import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography,
    Box,
    TextField
} from "@mui/material";

import { LoadingButton } from '@mui/lab';
import { makeStyles } from "@mui/styles";
import {
    addPartner, 
    getNinnekoPartnerContractOwner,
    getTokenPartnerAvailable,
    getTotalTokenPartner,
    claimTokenPartner
} from "../../service/vesting";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';
import { ConstructionOutlined, HelpOutlined } from "@mui/icons-material";
import { UnclaimedDialog } from "../../component/Misc/UnclaimedDialog";
import Countdown from 'react-countdown';

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    buyBox: {
        fontSize: "16px",
    },
    buyBoxButton: {
        // marginTop: "5px"
    },
    info: {
        paddingTop: 10
    }
}));

export function Partner() {
    const classes = useStyles()

    const [totalToken, setTotalToken] = useState(0);
    const [availableToken, setAvailableToken] = useState(0);
    const [walletAddress, setWalletAddress] = useState(null);
    const [contractOwner, setContractOwner] = useState(null)

    const addressRef = useRef()
    const amountRef = useRef()
    const timeRef = useRef()

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        setTotalToken(await getTotalTokenPartner());
        setAvailableToken(await getTokenPartnerAvailable())
        setContractOwner(await getNinnekoPartnerContractOwner())
    };

    useEffect(() => {
        setTimeout(() => {
            getInfo();
        }, 500)
        setTimeout(() => {
            getInfo();
        }, 2000)
    }, []);

    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }

    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClaim = async () => {
        setLoading(true);
        claimTokenPartner(enqueueSnackbar, () => {
            getInfo()
            setLoading(false)
        }, onError);
    };

    const handleAddClick = async () => {
        setLoading(true);
        addPartner(enqueueSnackbar, () => {
            setLoading(false)
        }, onError);
    };

    return (
        walletAddress ?
            <Grid container style={{ maxWidth: "800px" }}>
                <Grid item xs={12}>
                    <Card className={classes.banner}>
                        <Grid item xs={10}>
                            <Grid container>
                                <Grid item xs={12} className={classes.info}>
                                    <Typography color="textPrimary" variant="h5">
                                        NINO Partner
                                        <br />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} className={classes.info}>
                                    <Grid container>
                                        <Grid item xs={5} md={2} className={classes.buyBox}>
                                            <Typography color="textPrimary">Total NINO:</Typography>
                                        </Grid>

                                        <Grid item xs={5} md={10} className={classes.buyBox}>
                                            <Typography color="textPrimary">{totalToken}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.info}>
                                    <Grid container>
                                        <Grid item xs={5} md={2} className={classes.buyBox}>
                                            <Typography color="textPrimary">Available:</Typography>
                                        </Grid>
                                        <Grid item xs={5} md={10} className={classes.buyBox}>
                                            <Typography color="textPrimary">{availableToken}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={2} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <LoadingButton onClick={handleClaim} variant="contained"
                                disabled={walletAddress == null || availableToken <= 0}
                                loading={loading}
                                style={{ width: '100%', height: 40 }}>
                                Claim
                            </LoadingButton>
                        </Grid>
                    </Card>
                </Grid>


                {contractOwner && walletAddress == contractOwner &&
                    <Grid item xs={12}>
                        <Card className={classes.banner}>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.info}>
                                        <Typography color="textPrimary" variant="h5">
                                            Add partner
                                            <br />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} className={classes.info}>
                                        <Box component="form">
                                            <div ><TextField className={classes.field} inputRef={addressRef} label="Partner Address" type="text" /></div>
                                            <div><TextField className={classes.field} inputRef={amountRef} label="NINO Amount" type="text" /></div>
                                            <div><TextField className={classes.field} inputRef={timeRef} label="Time Release" type="text" /> </div>
                                        </Box >
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                <LoadingButton onClick={handleAddClick} variant="contained"
                                    disabled={walletAddress == null}
                                    loading={loading}
                                    style={{ width: '100%', height: 40 }}>
                                    Add
                                </LoadingButton>
                            </Grid>
                        </Card>
                    </Grid>
                }

            </Grid>
            :
            <Grid container>
                <Grid item xs={12}>
                    <h1>Partner</h1>
                    <span className={classes.desc}>
                        Please connect your Wallet first!
                    </span>
                </Grid>
            </Grid>
    );
}
