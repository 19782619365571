import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";
import { LoadingButton } from '@mui/lab';

import { PATH } from "../../config/routes";
import { makeStyles } from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import { WhitelistROIDialog } from "../../component/Misc/WhitelistROIDialog";
import { approveBuyNFT, approveMATA, approveNINO, approveSapphireStaking, buyNewGeneBox, buyNFTBox, buySapphireBoxStaking, checkAllowanceBuyNFT, checkApproveBuyMATA, checkApproveBuyNINO, checkApproveMATA, checkApproveNINO, checkApproveStakingContract, checkSoldOut, getNINOStaked, getNumberOfNINO, getTokenAvailable, stake, unstake } from "../../service/newgene";
import { BOX_DARK_SWORD, BOX_SAPPHIRE } from "../../config/staking";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';
import { HelpOutlined } from "@mui/icons-material";
import Countdown from 'react-countdown';
import { BuySapphireBoxDialog } from "../../component/Misc/BuySapphireBoxDialog";
import { MHidden } from "../../component/@material-extend";
import { BuyNewGeneBoxDialog } from "../../component/Misc/BuyNewGeneBoxDialog";
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    bannerImg: {
        display: "flex",
        borderRadius: "10px",
        backgroundImage: 'url("/images/banner1.jpg")',
        height: 450,
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    adBanner: {
        display: "flex",
        borderRadius: "10px",
        background: "#1976d2", //theme.palette.primary.main , // "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        // border: "1px solid #000",
        // boxSizing: "border-box",
        // boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "25px"
    },
    bannerLink: {
        fontSize: "16px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "20px",
        marginBottom: "30px"
    },
    buyBoxButton: {
        marginTop: "16px",
        backgroundColor: "#1976d2"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    boxImageWrapper: {
        width: "180px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "180px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
    info: {
        paddingTop: 10
    },
    btnApproved: {
        backgroundColor: "white !important",
        border: '1px solid rgba(145, 158, 171, 0.8)',
        color: 'rgb(33, 43, 54) !important'
    },
    btnNotApproved: {
        //cái màu nền  này làm cho cái loading bị ẩn ko nhìn đc
        //backgroundColor: "rgb(0, 171, 85) !important",
        // backgroundColor: "white !important",
        // '&:hover': {
        //     backgroundColor: 'rgb(0, 125, 85) !important'
        // },

    },
    price: {
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //padding: theme.spacing(1)
    },
}));

const REQUIRED_NUMBER_NINO_STAKING = 10000
const REQUIRED_NUMBER_NINO_BUY_BOX = 10000

export function NewGenes() {
    const classes = useStyles()

    const [walletAddress, setWalletAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [openBuyBox, setOpenBuyBox] = useState(false);
    const [approved, setApproved] = useState(false);
    const [isSoldOut, setIsSoldOut] = useState(true);

    const [availableToken, setAvailableToken] = useState(0);
    const [approvedStaking, setApprovedStaking] = useState(null);
    const [myNINOStaked, setMyNINOStaked] = useState(0)

    const [stakeValue, setStakeValue] = useState(0);


    //const [walletAddress, setWalletAddress] = useState(null);
    const [boolApproveNINO, setBoolApproveNINO] = useState(false)
    const [boolApproveMATA, setBoolApproveMATA] = useState(false)
    const [loadingApproveNINO, setLoadingApproveNINO] = useState(false)
    const [loadingApproveMATA, setLoadingApproveMATA] = useState(false)

    const getInfo = async () => {
        await setWalletAddress(sessionData.myAddress);
        checkApproveNINO(setBoolApproveNINO)
        checkApproveMATA(setBoolApproveMATA)
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 500)
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 2000)
        // let myInterval = setInterval(() => {
        //     getInfo();
        // }, 20000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    }, []);

    // const onError = () => {
    //     console.log("clean loading")
    //     setLoading(false)
    // }

    const onError = () => {
        setLoading(false)
        setLoadingApproveNINO(false)
        setLoadingApproveMATA(false)
    }

    const handleApproveNINO = () => {
        setLoadingApproveNINO(true)
        if (!boolApproveMATA) {
            setLoadingApproveMATA(true)
        }
        approveNINO(enqueueSnackbar, () => {
            setLoadingApproveNINO(false)
            setLoadingApproveMATA(false)
            setBoolApproveNINO(true)
        }, onError);

    }

    const handleApproveMATA = () => {
        if (!boolApproveNINO) {
            setLoadingApproveNINO(true)
        }
        setLoadingApproveMATA(true)
        approveMATA(enqueueSnackbar, () => {
            setLoadingApproveNINO(false)
            setLoadingApproveMATA(false)
            setBoolApproveMATA(true)
        }, onError);
        // if (checkApproveMATA){
        // //     setLoading(false)
        //     setBoolApproveMATA(true)
        // }
    }





    const hanldeBuyNewGeneBox = (numberOfBox) => {
        setOpenBuyBox(false);
        setLoading(true);
        buyNewGeneBox(numberOfBox, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Buy " + parseInt(numberOfBox) + " boxes successfully!", { variant: 'success', autoHideDuration: 10000 });
        }, onError);
    }



    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>;
        } else {

            return <span>Time left: {days}d {hours}h {minutes}min {seconds}</span>;
        }

    };

    return (

        <Grid container style={{ maxWidth: "800px" }}>
            {/* <MHidden width='mdDown'>
                <Grid item xs={12}>
                    <Card className={classes.bannerImg}>

                    </Card>
                </Grid>
            </MHidden> */}
            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={12} md={6} lg={6}>
                        <div>
                            <Typography color="textPrimary" variant="h5" style={{ paddingBottom: 6 }}>
                                Dark Sword Box
                                <br />
                                <Typography color="textPrimary">
                                    100% Dark Sword Hand
                                </Typography>
                            </Typography>
                            {/* <Typography color="textPrimary">Event has ended</Typography> */}

                            <Typography color="#3990f8" variant="body2">
                                <Countdown
                                    date={"Thu Oct 06 2022 15:00:00 GMT+0700"}
                                    renderer={renderer}
                                />
                            </Typography>


                            <div className={classes.buyBox}>



                                {/* <Typography color="textPrimary">Price: 1200 NINO and 50.000 MATA</Typography> */}


                                <Grid container spacing={1} >
                                    {/* <Grid item xs={12} md={3}>
                                        <div className={classes.price}>
                                            <Typography>Price:</Typography>
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={12} md={4.5}>
                                        <LoadingButton onClick={handleApproveNINO} variant="contained"
                                            disabled={boolApproveNINO}
                                            loading={loadingApproveNINO}

                                            className={boolApproveNINO ? classes.btnApproved : classes.btnNotApproved}
                                            // loadingIndicator="Aproving..."
                                            style={{ width: '100%', height: '100%' }}>
                                            <img src="/images/svg/coin_nino.svg" style={{ width: 30, height: 30, marginRight: 8 }} />
                                            {boolApproveNINO &&
                                                <Typography>1,200</Typography>
                                            }
                                            {!boolApproveNINO && "Approve"}
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} md={4.5}>
                                        <LoadingButton onClick={handleApproveMATA} variant="contained"
                                            disabled={boolApproveMATA}
                                            loading={loadingApproveMATA}
                                            className={boolApproveMATA ? classes.btnApproved : classes.btnNotApproved}
                                            // loadingIndicator="Aproving..."
                                            style={{ width: '100%', height: '100%' }}>
                                            <img src="/images/svg/coin_mata.svg" style={{ width: 30, height: 30, marginRight: 8 }} />
                                            {boolApproveMATA &&
                                                <Typography>50,000</Typography>
                                            }
                                            {!boolApproveMATA && "Approve"}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>

                                {boolApproveMATA && boolApproveNINO && <Button className={classes.buyBoxButton} disabled={loading || !walletAddress}
                                    onClick={() => setOpenBuyBox(true)} variant="contained">Buy Box
                                </Button>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container>
                            {
                                BOX_DARK_SWORD.map((box, key) => {
                                    return (
                                        <Grid item xs={6} md={6} key={key}>
                                            <div className={classes.box}>
                                                <div className={classes.boxImageWrapper}>
                                                    <div className={classes.boxImage}>
                                                        <img src={box.boxImage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                }
                                )
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <BuyNewGeneBoxDialog buyNFTBox={hanldeBuyNewGeneBox} loading={loading}
                open={openBuyBox} onClose={() => setOpenBuyBox(false)} />
        </Grid>


        // </Grid>
    );
}


