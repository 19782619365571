import {BootstrapDialog, BootstrapDialogTitle} from "./BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const APYFlexible=({open,onClose})=>{
    return(
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                APR
            </BootstrapDialogTitle>
            <DialogContent style = {{paddingTop: 13}}>
                <Typography variant="body2">
                   - The APR will fluctuate based on the amount of NINO staked in the pool.<br/>
                    (The less NINO, the more APR and vice versa)
                </Typography>
            </DialogContent>
        </BootstrapDialog>
    )
}