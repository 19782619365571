import React, {useEffect, useState} from "react";
import {
    Button,
    Card, Divider,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";

import {PATH} from "../../config/routes";
import {makeStyles} from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import {WhitelistROIDialog} from "../../component/Misc/WhitelistROIDialog";
import {BOX_STAKE_FELIX, BOX_TYPE_RUBY, BOX_WHITE_LIST} from "../../config/staking";
import {useSnackbar} from "notistack";
import {BuyBoxWhitelistDialog} from "../../component/Misc/BuyBoxWhitelistDialog";
import sessionData from '../../sessionData';
import {NETWORK_RPC_URL, TEST_NET} from "../../util/constants";
import {getWalletAddress, getWeb3} from "../../util/cryptoWallet";
import {handleResult} from "../../util/utils";
import Web3 from "web3";
import {POOL} from "../../service/whitelist";
import {getTokenAvailableS, getTotalToken} from "../../service/vesting";
import {LoadingButton} from "@mui/lab";
import {APYFlexible} from "../../component/Misc/APYDesc";
import {NinnekoStakeFelix, NinnekoStakeFelixAddress} from "./StakeFelix";
import {userInfo} from "os";

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "25px"
    },
    bannerLink: {
        fontSize: "14px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "20px"
    },
    buyBoxButton: {
        marginTop: "5px"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    boxImageWrapper: {
        width: "135px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "120px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
}));
const NinnekoAddress = TEST_NET ? "0x00738A3b92AcD2962202eE3370E91a068bcABC50" : "0x6cad12b3618a3c7ef1feb6c91fdc3251f58c2a90";
const TokenABI = [{"inputs": [], "stateMutability": "nonpayable", "type": "constructor"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
    "name": "Approval",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
    "name": "Transfer",
    "type": "event"
}, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }],
    "name": "allowance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
    }],
    "name": "approve",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "decimals",
    "outputs": [{"internalType": "uint8", "name": "", "type": "uint8"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
        "internalType": "uint256",
        "name": "subtractedValue",
        "type": "uint256"
    }],
    "name": "decreaseAllowance",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
        "internalType": "uint256",
        "name": "addedValue",
        "type": "uint256"
    }],
    "name": "increaseAllowance",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "name",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "symbol",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "recipient", "type": "address"}, {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
    }],
    "name": "transfer",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "sender", "type": "address"}, {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
    }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
    "name": "transferFrom",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
}]
const claimToken = (snackbar, onComplete, onError) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(NinnekoStakeFelix, NinnekoStakeFelixAddress);
    handleResult(contract.methods.unStakeV2(2), snackbar, onComplete, onError);
}

const StakeLock2 = () => {
    const classes = useStyles()
    const POOL = [{
        name: "NINO",
        poolId: 2,
        approved: false,
        totalNinoStake:0,
        myEntries:0,
    }]
    const [pools, setPools] = useState(POOL);
    const [walletAddress, setWalletAddress] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [canBuyBox, setCanBuyBox] = useState(false);
    const [token, setToken] = useState({totalToken: 0, availableToken: 0});

    const DECIMAL = 1e18;
    const fetchPoolInfo = async () => {
        let web3 = getWeb3();

        if (!web3) web3 = new Web3(NETWORK_RPC_URL);
        // const web3 = new Web3(NETWORK_RPC_URL);


        const ninnekoWhitelist = web3 && (new web3.eth.Contract(NinnekoStakeFelix, NinnekoStakeFelixAddress));

        var pools = [];
        for (let i = 0; i < POOL.length; i++) {
            let pool = POOL[i];
            //console.log("call poolInfo");
            if (web3 !== null) {
                const ninneko = new web3.eth.Contract(TokenABI, NinnekoAddress);
                var totalNinoStake = await ninneko.methods.balanceOf(NinnekoAddress).call({from: null}).then(totalNinoStake => {
                    return totalNinoStake / DECIMAL
                })
                var poolInfo = await ninnekoWhitelist.methods.poolInfo(pool.poolId).call({from: null}).then(poolInfo => {
                    var rewardPerSecond = 0.01;
                    return {
                        name: pool.name, poolId: pool.poolId, totalNinoStake: poolInfo.totalNinoStake / DECIMAL,
                        tokenContract: poolInfo.lpToken, rewardPerSecond: poolInfo.rewardNinoPerSecond, approved: false
                    };
                }).then(async poolInfo => {
                    const address = await getWalletAddress();

                    if (address !== null) {

                        var user = null;
                        await ninnekoWhitelist.methods.userInfo(pool.poolId, address).call({from: address}).then(userInfo => {
                            poolInfo.myEntries = Math.round(userInfo.amount / DECIMAL)
                            user = userInfo;

                        })
                        await ninnekoWhitelist.methods.pendingReward(pool.poolId, address).call({from: address}).then(point => {
                            poolInfo.myEarn = (( parseInt(point) +parseInt(user.unclaimed)  ) / DECIMAL).toFixed(2)
                        })

                        await ninnekoWhitelist.methods.calRewardV2Claimed(address).call({from: address}).then(point => {
                            poolInfo.myEarn +=0
                            /*    ( parseInt(point) / DECIMAL).toFixed(2)*/
                        })
                        console.log(user)
                        var tokenContract = await ninnekoWhitelist.methods.NINO_TOKEN().call({from: null});
                        //console.log(tokenContract)
                        const tokenLP = new web3.eth.Contract(TokenABI, tokenContract);
                        await tokenLP.methods.allowance(address, NinnekoStakeFelixAddress).call({from: address}).then((data) => {
                            poolInfo.approved = data > 0;
                            poolInfo.tokenContract = tokenContract;
                            return poolInfo;
                        })
                    }
                    return poolInfo;
                })
                pools.push(poolInfo);
            }
        }
        return pools;
    }

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        setPools(await fetchPoolInfo());
        setToken({
            ...token,
            totalToken: await getTotalToken(NinnekoStakeFelix, NinnekoStakeFelixAddress,2),
            availableToken: await getTokenAvailableS(NinnekoStakeFelix, NinnekoStakeFelixAddress,2)
        })
        console.log("Get Info ----------------------->")
        if (sessionData.myAddress) {
            for (let i = 0; i < arrResult.length; i++) {
                if (arrResult[i].address == sessionData.myAddress) {
                    setCanBuyBox(false)
                }
            }

            for (let i = 0; i < arrResultBonus.length; i++) {
                if (arrResultBonus[i].address == sessionData.myAddress) {
                    setCanBuyBox(false)
                }
            }
        }
    };

    const arrResult = [{
        address: "0xD1944C6d70887c09759d1fc31fF697a449C69B9E",
        cutAddress: "0xD1944C........C69B9E",
        number: 56
    },
        {address: "0x6724e84bbF4A8FeE5200BEc0a8cB6FFfEa5789ef", cutAddress: "0x6724e8........5789ef", number: 47},
        {address: "0x5fA89b0e7F89F14590397cf45cAfC96e3ecd2CB1", cutAddress: "0x5fA89b........cd2CB1", number: 47},
        {address: "0x063bd7CeB92820014855F20066A58a236f554347", cutAddress: "0x063bd7........554347", number: 42},
        {address: "0xbdFdEb8434ae83DB7F40cF4EaEF7624656B3949D", cutAddress: "0xbdFdEb........B3949D", number: 25},
        {address: "0xa1A57c18Edb795b7BeaB57eE1a4f11C5Fb53215D", cutAddress: "0xa1A57c........53215D", number: 27},
        {address: "0x9426b3098565B3721FaAa314877a3bdE273Af3EB", cutAddress: "0x9426b3........3Af3EB", number: 19},
        {address: "0x617F0A23F7B02a60fEc320fa9b92De3A0a23A0Ba", cutAddress: "0x617F0A........23A0Ba", number: 24},
        {address: "0xeF68B128dc2fdBd0d910e96ca014eab24e932b3b", cutAddress: "0xeF68B1........932b3b", number: 13},
        {address: "0xEfd6Cc09d858a8cb06EC5A40812E530620F80A50", cutAddress: "0xEfd6Cc........F80A50", number: 20},
        {address: "0xa016Ffd5397E1C900f7bf68Bd2137924D1Ca0616", cutAddress: "0xa016Ff........Ca0616", number: 21},
        {address: "0xDdbA314F96C62BFf8745b23a44a22C540108Cb7a", cutAddress: "0xDdbA31........08Cb7a", number: 15},
        {address: "0x168D25eB1BCBbFe563b4680156381A57D32537EB", cutAddress: "0x168D25........2537EB", number: 21},
        {address: "0x8fed5F8dB95Bb48EC59D67D7C1744DF93FBf6385", cutAddress: "0x8fed5F........Bf6385", number: 12},
        {address: "0x83C18749919b0b9Fee9e4d7d3d89Bc6Ba5043cd4", cutAddress: "0x83C187........043cd4", number: 10},
        {address: "0x27f6ccBC7C413Bb99aeEF6fC8226dAF22A861561", cutAddress: "0x27f6cc........861561", number: 15},
        {address: "0xD792f44Bd1f4095FeD23e805ebC4a46D9e62CEB5", cutAddress: "0xD792f4........62CEB5", number: 9},
        {address: "0x8ff6BD47138138564E64c79FD431A04a837Fa1F9", cutAddress: "0x8ff6BD........7Fa1F9", number: 11},
        {address: "0x4E8315ad5604B3C46E4F51Ce8b651f899FD3B5B4", cutAddress: "0x4E8315........D3B5B4", number: 9},
        {address: "0x99C1f04f8F68Fc56Aba130932876eacaC4f0D1A1", cutAddress: "0x99C1f0........f0D1A1", number: 10},
        {address: "0x6bBb4225c032615Ce6101710b707FA642FDe6267", cutAddress: "0x6bBb42........De6267", number: 11},
        {address: "0x1Ce1977c4381C6Fa89365C62996FA7191562A28F", cutAddress: "0x1Ce197........62A28F", number: 8},
        {address: "0x32c375567A5F693A2DA93666610941ae42136cdE", cutAddress: "0x32c375........136cdE", number: 8},
        {address: "0x60f200Ac722EAE79E7babfFd7925900bf2f6D76B", cutAddress: "0x60f200........f6D76B", number: 7},
        {address: "0x558121212F028c46441FdD1A57Af21111A49eDa2", cutAddress: "0x558121........49eDa2", number: 8},
        {address: "0x0Ff45A0581546189019965A9657B3AFeEB2879cA", cutAddress: "0x0Ff45A........2879cA", number: 1},
        {address: "0xeBbeC62FF78267550c853de9A63E79c0EA54c190", cutAddress: "0xeBbeC6........54c190", number: 3},
        {address: "0x7af0510b159a5eb4028aADE16e5a519cdfF01dF7", cutAddress: "0x7af051........F01dF7", number: 5},
        {address: "0xa295657Cd06ebD826B6Be9Ded29D9e0e2Cd9Bc65", cutAddress: "0xa29565........d9Bc65", number: 8},
        {address: "0xc3183336b4e76aB66a659D42cF57995F43562E54", cutAddress: "0xc31833........562E54", number: 7},
        {address: "0x6025484e7B21A7E2829c874275622eF22c46ae04", cutAddress: "0x602548........46ae04", number: 7},
        {address: "0xc26E3F96Fe5c76EA141e3e6aC5f64c5060228512", cutAddress: "0xc26E3F........228512", number: 8},
        {address: "0x76c81624B88cfFDf508F8fB466E67e6695666666", cutAddress: "0x76c816........666666", number: 8},
        {address: "0x8Ae5123209Cd10748FF28a6C0b96f3C1F422f926", cutAddress: "0x8Ae512........22f926", number: 6},
        {address: "0xAC42CBDe744c1789F844e25096095104Fb3fcfAD", cutAddress: "0xAC42CB........3fcfAD", number: 5},
        {address: "0xFFF10DA04f9c6313E53FEd3e1B0a8E9833Dc39DC", cutAddress: "0xFFF10D........Dc39DC", number: 5},
        {address: "0xF0163a5eb4077A58ae9CE46b13fEA0296D374131", cutAddress: "0xF0163a........374131", number: 3},
        {address: "0xA85A18ffF0ABdcB1094c9DD8FcD18F54901069F0", cutAddress: "0xA85A18........1069F0", number: 2},
        {address: "0xcadB382F48C559fb8DB761793F0da63931a4938c", cutAddress: "0xcadB38........a4938c", number: 2},
        {address: "0x2798dc5A5C31112aeF3D5ff43bDc668215326CB8", cutAddress: "0x2798dc........326CB8", number: 2},
        {address: "0x060Efb5d971540104BcD3CFE0f3676B8889dEc97", cutAddress: "0x060Efb........9dEc97", number: 6},
        {address: "0xe837B70CB5D44D0F6cACF5fb3C2213561aD3dECE", cutAddress: "0xe837B7........D3dECE", number: 4},
        {address: "0xC1566Db4c9B77Aa517AF21FAD7B1e220919A151D", cutAddress: "0xC1566D........9A151D", number: 2},
        {address: "0x6b34eb900EAb83c5f34849045e2209c57709f5d1", cutAddress: "0x6b34eb........09f5d1", number: 4},
        {address: "0x0a309842CaAd7300239f9a8EdDc7C1C5A29F8f8a", cutAddress: "0x0a3098........9F8f8a", number: 2},
        {address: "0xB56269073f2D93E158C698ebddBf4d237aE02574", cutAddress: "0xB56269........E02574", number: 4},
        {address: "0x495d2C6416B1d9b11a3e9cA6dEd5dB3dF2f957A7", cutAddress: "0x495d2C........f957A7", number: 7},
        {address: "0x8593A389574DAf194a0476124443031aFA0aFCc0", cutAddress: "0x8593A3........0aFCc0", number: 3},
        {address: "0x25f7D7D6f84aE0B544C87b59B982FffCd3A166aA", cutAddress: "0x25f7D7........A166aA", number: 4},
        {address: "0x3D0b9E875b56D8ba9549AB4aBC553cdB50F62a8E", cutAddress: "0x3D0b9E........F62a8E", number: 3},
        {address: "0xB9C62235b4B1caf59edCdF9b3728715c4562a058", cutAddress: "0xB9C622........62a058", number: 1},
        {address: "0x81494bc4362AC25Bfe93266bC9E4F8622B4291cc", cutAddress: "0x81494b........4291cc", number: 4},
        {address: "0x82fe385E4E80658f28534b916733d36fBA4D0B39", cutAddress: "0x82fe38........4D0B39", number: 6},
        {address: "0xcC266B09753F842F2C80c2EE1EF4B1052226a6d5", cutAddress: "0xcC266B........26a6d5", number: 3},
        {address: "0x4E8AB01b2097e35340c52bB2923344Ba3A163AaD", cutAddress: "0x4E8AB0........163AaD", number: 3},
        {address: "0x3B57AeFcc86BA3262a25893B3612B2632Ea509fd", cutAddress: "0x3B57Ae........a509fd", number: 8},
        {address: "0xf3F95383597BBB3ff31f78E1EF038A2A490Bd362", cutAddress: "0xf3F953........0Bd362", number: 4},
        {address: "0xAc215021B8FEA08a7fc80b4E8f8E0eC004304c69", cutAddress: "0xAc2150........304c69", number: 3},
        {address: "0xb5BabF8442369C512EB596bBb9A628E7A0c6c41A", cutAddress: "0xb5BabF........c6c41A", number: 5},
        {address: "0x6100Bf8932A8b9B28B91debCf623b3277e9DfE54", cutAddress: "0x6100Bf........9DfE54", number: 2},
        {address: "0x823DE5D5CE958435C403C9340DA2231b39Cfe318", cutAddress: "0x823DE5........Cfe318", number: 4},
        {address: "0xB2A1662BB65247Da080457442f7450EFc8FdfB58", cutAddress: "0xB2A166........FdfB58", number: 2},
        {address: "0xbC806AF6733eFe556CA838dc8712eE45A9f4Fc48", cutAddress: "0xbC806A........f4Fc48", number: 4},
        {address: "0xD0439e46267BDf5D4608118Ae3A0F1854a9C7126", cutAddress: "0xD0439e........9C7126", number: 2},
        {address: "0x980ce73Da1E93B678749D1b0c99B6407029BebC5", cutAddress: "0x980ce7........9BebC5", number: 4},
        {address: "0xb535995557Ed2C23A134D1E1950077844D6bC8fE", cutAddress: "0xb53599........6bC8fE", number: 8},
        {address: "0x10F84a368160630e8348ED94F2a1c638660F3dA8", cutAddress: "0x10F84a........0F3dA8", number: 2},
        {address: "0x387B2F161e557E6B68526d0cb83E88Ad9aF0c4a3", cutAddress: "0x387B2F........F0c4a3", number: 2},
        {address: "0x59ebeF6463565445Db6868C43CEEF3551f15c875", cutAddress: "0x59ebeF........15c875", number: 4},
        {address: "0x5311Ab9b139Ec10a6d2636097b36C9fE027A1DD7", cutAddress: "0x5311Ab........7A1DD7", number: 2},
        {address: "0xEf8Ec6178F2F9F64d42a38b2aCdd535E4a0088BD", cutAddress: "0xEf8Ec6........0088BD", number: 3},
        {address: "0x9f1a502c7CFf79dFD24D23cc524fC3af50dc4628", cutAddress: "0x9f1a50........dc4628", number: 1},
        {address: "0xCBDD3D573D87E40e78b509BB2E6A2a1D76BE3f41", cutAddress: "0xCBDD3D........BE3f41", number: 1},
        {address: "0x6a627b7Dcb77B9A57f3987eCB118b6EDC6A5094e", cutAddress: "0x6a627b........A5094e", number: 1},
        {address: "0xAcBeb18c8DA7D0Ba42301F1c70f010FA3dFE8159", cutAddress: "0xAcBeb1........FE8159", number: 1},
        {address: "0x6BAdeD2278A554e18A52F21f0c75cbB251149fdf", cutAddress: "0x6BAdeD........149fdf", number: 2},
        {address: "0xe7f92D96348af8b90F3C14DfAC218f2CC7F74710", cutAddress: "0xe7f92D........F74710", number: 5},
        {address: "0xBEE089cFa347E4FAF57c0b0F98aB81A6343b9bee", cutAddress: "0xBEE089........3b9bee", number: 2},
        {address: "0xeD883302e350E8a86D80215898653E1b3595F568", cutAddress: "0xeD8833........95F568", number: 7},
        {address: "0xdF40920f41D6Df69954017ed7Ebb15FB1795B116", cutAddress: "0xdF4092........95B116", number: 1},
        {address: "0xFBA50116dabd78aaf1Fc5D11d512cCa47d210b95", cutAddress: "0xFBA501........210b95", number: 2},
        {address: "0x031f6F875AF6DA42782275C7716f5b8F325DeB28", cutAddress: "0x031f6F........5DeB28", number: 4},
        {address: "0x23c2540fe9448F7954Ee71BA661873df305A14c8", cutAddress: "0x23c254........5A14c8", number: 2},
        {address: "0xd4eAe7702a5704687Fb94F9ABb01cDD5901f94cB", cutAddress: "0xd4eAe7........1f94cB", number: 2},
        {address: "0xeBfEB2C445DB479B2EB11C3dECFe9d3eD0E086fd", cutAddress: "0xeBfEB2........E086fd", number: 2},
        {address: "0x14C263F01E6654D7B6aCC6E442d88dFB7C4d0a44", cutAddress: "0x14C263........4d0a44", number: 1},
        {address: "0x60e6d828cB037E5F9E9b4b3fFAE7A715731E0b1C", cutAddress: "0x60e6d8........1E0b1C", number: 1},
        {address: "0xa8852a7c6Bb378Fd2149d33bDD6FEf0E0541fb66", cutAddress: "0xa8852a........41fb66", number: 3},
        {address: "0x134Da0703b3ab76998c8ed905af5f65747564fe8", cutAddress: "0x134Da0........564fe8", number: 1},
        {address: "0x2e2de5D2ECf623465e00c65Aeb0858307900d284", cutAddress: "0x2e2de5........00d284", number: 2},
        {address: "0x176A512156D2CE67C1D7d1f4189e7FB85d26d4b7", cutAddress: "0x176A51........26d4b7", number: 1},
        {address: "0x46BA07a3B74E8386b67F8eb614479f35B6a8AB58", cutAddress: "0x46BA07........a8AB58", number: 1},
        {address: "0x45c22af7883Ef71d3f9E0E01D653B202F1E13091", cutAddress: "0x45c22a........E13091", number: 2},
        {address: "0x2f27Fa9484BD123d524969743C41C4943e131C67", cutAddress: "0x2f27Fa........131C67", number: 2},
        {address: "0x1D911C979603123db7588ACCc02Ec7045b423836", cutAddress: "0x1D911C........423836", number: 2},
        {address: "0x67647070B9d84f9E6583eEF15009CD75c18fBd82", cutAddress: "0x676470........8fBd82", number: 1},
        {address: "0x64fDF17b19FD71C045C49fD4821E3baa59fA407e", cutAddress: "0x64fDF1........fA407e", number: 3},
        {address: "0x44c8a0cD83C670781Cd69D27F4B9D39eb6b8bf66", cutAddress: "0x44c8a0........b8bf66", number: 2},
        {address: "0xC76318efb58665E4Bf32c5c480181d7528eDC342", cutAddress: "0xC76318........eDC342", number: 1},
        {address: "0x08923CD2FF0a7d248BB976568c085157d12aA2dd", cutAddress: "0x08923C........2aA2dd", number: 3},
        {address: "0x973d93054c5bd820c1cF4426660ED41840d5f6Fe", cutAddress: "0x973d93........d5f6Fe", number: 6},
        {address: "0x5Ce00bB8a79E6396Fcf8f7D6c356a681a342Cf1A", cutAddress: "0x5Ce00b........42Cf1A", number: 1},
        {address: "0xa3758C157c8e301d003AC1D54fdC77e91a9511E0", cutAddress: "0xa3758C........9511E0", number: 2},
        {address: "0x50a0de33271275E1D7d42a58779dd55835ce9A96", cutAddress: "0x50a0de........ce9A96", number: 2},
        {address: "0xA4100DD416AAf7A48CFbf5F4Dd1Fa6dA24e3A1Ef", cutAddress: "0xA4100D........e3A1Ef", number: 3},
        {address: "0x830ed49AE6B3882F539C259F82890202cd279073", cutAddress: "0x830ed4........279073", number: 2},
        {address: "0xE9d8FF37aA35444Ca19a683eEAc3495e9d2C42D6", cutAddress: "0xE9d8FF........2C42D6", number: 2},
        {address: "0x4741225Ea9B2498bC264b241F241a6df0a82030E", cutAddress: "0x474122........82030E", number: 2},
        {address: "0xbc8668c69d6bd61695502466Cd08778fbDCC607d", cutAddress: "0xbc8668........CC607d", number: 3},
        {address: "0x4Be4508F2d199bEcbC2f8408CC4dEbA92a007617", cutAddress: "0x4Be450........007617", number: 2},
        {address: "0x90f4127DA5e2ad93dfC37bd697afa4132F46F757", cutAddress: "0x90f412........46F757", number: 2},
        {address: "0xD257Ff98CE155948dab554CC78027B33cC6CFc11", cutAddress: "0xD257Ff........6CFc11", number: 2},
        {address: "0xDe8494150C091D12093B344D1FA4e19f15F1C480", cutAddress: "0xDe8494........F1C480", number: 2},
        {address: "0xBF208438dD4594F4F0C4049278f5D3d33309F9e4", cutAddress: "0xBF2084........09F9e4", number: 3},
        {address: "0xc45c5d96f68884e829155320f2fe65C825D71Bd3", cutAddress: "0xc45c5d........D71Bd3", number: 1},
        {address: "0x4039c733e9A86c8dB45BFd671c54733CA7EAA58E", cutAddress: "0x4039c7........EAA58E", number: 2},
        {address: "0xb3Ea83C3CD74bECD352eAA96481c84E36bd58D8B", cutAddress: "0xb3Ea83........d58D8B", number: 1},
        {address: "0xd4eD2Bfce192af36f62292EeA6CCC7200B1c7534", cutAddress: "0xd4eD2B........1c7534", number: 1},
        {address: "0x54E2DE394ADFd41Ae4CB8987362FaD6069623B9A", cutAddress: "0x54E2DE........623B9A", number: 2},
        {address: "0x3505681D513A39191084bf9b8a1e2c8d039380FA", cutAddress: "0x350568........9380FA", number: 3},
        {address: "0xf55B9036D6d82aB34b455125Bf89F1fd030188D4", cutAddress: "0xf55B90........0188D4", number: 3},
        {address: "0x72D00764DE2BD3b0746C8d9859C2a8c6E9743b62", cutAddress: "0x72D007........743b62", number: 1},
        {address: "0xeCD52C414044ED9B45E498b47165E72Eb61272a9", cutAddress: "0xeCD52C........1272a9", number: 2},
        {address: "0x4eA6de2e7907A698A9DfE5800fd2CD8A1B85114d", cutAddress: "0x4eA6de........85114d", number: 3},
        {address: "0x182Dde2E4a3765D78Bd8546023970Ae812C9F73c", cutAddress: "0x182Dde........C9F73c", number: 1},
        {address: "0xd4eD713cFd16212e33F8985D411C4c002736cA3C", cutAddress: "0xd4eD71........36cA3C", number: 1},
        {address: "0x6021A327f68CD58eBf4Eb0f01e7f93b96443AA3D", cutAddress: "0x6021A3........43AA3D", number: 1},
        {address: "0x2EBe42c0b728469CF77421a816D7056b597168b3", cutAddress: "0x2EBe42........7168b3", number: 1},
        {address: "0x0dF82F4E34F1f0249b696dF8709Ee0E207Fc5539", cutAddress: "0x0dF82F........Fc5539", number: 2},
        {address: "0x12cD82510EcbbCD3db4a2c0713d931254Dbd7647", cutAddress: "0x12cD82........bd7647", number: 1},
        {address: "0x6Dc10c2566e8Feef87033f56Bb60dB729aCE1D80", cutAddress: "0x6Dc10c........CE1D80", number: 1},
        {address: "0x47c55A7b1c01D3bfB4ec29776c6bf60c6Faad85f", cutAddress: "0x47c55A........aad85f", number: 1},
        {address: "0x317CC46Cd043f347F69fE891E871048Ea47B7f8c", cutAddress: "0x317CC4........7B7f8c", number: 1},
        {address: "0x3F036132eCB83f828c5160771C491D48d36fE56e", cutAddress: "0x3F0361........6fE56e", number: 1},
        {address: "0xEe0c727939670A44e54cEaeae99B5aabf8EE6DEf", cutAddress: "0xEe0c72........EE6DEf", number: 2},
        {address: "0x2d07aC521AEE7A9d74c8736e754D30169EDBcC0d", cutAddress: "0x2d07aC........DBcC0d", number: 1},
        {address: "0x65187967c2Cb582025a337eb6E5552d009226c27", cutAddress: "0x651879........226c27", number: 2},
        {address: "0xA98Bd681775CA29f3c5Cc2326C38e641b034F6AE", cutAddress: "0xA98Bd6........34F6AE", number: 1},
        {address: "0xE58C3e5fdE5dd2664BCA67C70FCE74319BA1e65c", cutAddress: "0xE58C3e........A1e65c", number: 1},
        {address: "0xCf36E0BdD9Da5D58C38665C0ac256aA250074ac9", cutAddress: "0xCf36E0........074ac9", number: 2},
        {address: "0x9222DF90bBDd1E421CB85d982d8D29E1D489B45E", cutAddress: "0x9222DF........89B45E", number: 1},
        {address: "0x88e8CF8632D14722FCa0C9671fd835E4fC1CBcA2", cutAddress: "0x88e8CF........1CBcA2", number: 1},
        {address: "0xf8cFA024Eb9da2bD4e4312da1B97B56596467F5c", cutAddress: "0xf8cFA0........467F5c", number: 1},
        {address: "0x14332f4b612A2406cD2C4AF73798211ABbF4B049", cutAddress: "0x14332f........F4B049", number: 1},
        {address: "0x089ca62AE277595c02c4BC6747D9aE9A909127E4", cutAddress: "0x089ca6........9127E4", number: 1},
        {address: "0x2C87241C50Ff069820498fE2818904f4624DcDbe", cutAddress: "0x2C8724........4DcDbe", number: 3},
        {address: "0x70ce3E6AeED49a7FeF8a936F4CcA778CB7dF151D", cutAddress: "0x70ce3E........dF151D", number: 1},
        {address: "0x95C044fc1b82C14684F5C10a3A5A1dcCd3AF3f7c", cutAddress: "0x95C044........AF3f7c", number: 2},
        {address: "0x5EEF432295aC538D3CA357ac58aA697D730fc89c", cutAddress: "0x5EEF43........0fc89c", number: 1},
        {address: "0x8ea2Bef5dbeD6a00eff4f498FB65eC8c5A6214e5", cutAddress: "0x8ea2Be........6214e5", number: 1},
        {address: "0x63E27FD26DD5dF836a68086B209022182e95Dde1", cutAddress: "0x63E27F........95Dde1", number: 1},
        {address: "0x619111a4585F5Aca4d97BeD91361B9C7c59B1eDc", cutAddress: "0x619111........9B1eDc", number: 3},
        {address: "0x4a746c987A26d3497c98D41D9fec68576e8B7Bb9", cutAddress: "0x4a746c........8B7Bb9", number: 1},
        {address: "0x4293DC115a32D8DB370799Fda3cd5466A46cC94A", cutAddress: "0x4293DC........6cC94A", number: 1},
        {address: "0x81DFac3ba42575dd56F3b9980746b9D09766d2DF", cutAddress: "0x81DFac........66d2DF", number: 1},
        {address: "0x94f6B2a488b12F427858Da6af4b63D32D36154a2", cutAddress: "0x94f6B2........6154a2", number: 2},
        {address: "0xeCD4B68FE1C46B6582564d90Df0fcc05ce13047A", cutAddress: "0xeCD4B6........13047A", number: 3},
        {address: "0xE076632bbBf4162Cd773Dd17dB2Aff61ab56779E", cutAddress: "0xE07663........56779E", number: 1},
        {address: "0xc4c5774aC4f69Fd3e4F5CB558323Fa19AFF53364", cutAddress: "0xc4c577........F53364", number: 1},
        {address: "0x3D16103c750Cd3A8a9E8222323c17EC2CE1b8566", cutAddress: "0x3D1610........1b8566", number: 1},
        {address: "0xe6A4FaC0bB2307AFeb1729A376A62a8f224237b9", cutAddress: "0xe6A4Fa........4237b9", number: 3},
        {address: "0x96bcCA58eF128012bc0720AE84d1C30984B29DCe", cutAddress: "0x96bcCA........B29DCe", number: 4},
        {address: "0x6473Fc6B8F3622bD7dE97fb73B2100e2ffc60ACd", cutAddress: "0x6473Fc........c60ACd", number: 3},
        {address: "0x8222d56098e57124Fb7B4C852d142E6802032Cf3", cutAddress: "0x8222d5........032Cf3", number: 2},
        {address: "0xA805eEd070b948862D74d0b4E3B754979624fb0A", cutAddress: "0xA805eE........24fb0A", number: 2},
        {address: "0x918eee75197Cc9C5800Cd880000Aa9FAcCA1674E", cutAddress: "0x918eee........A1674E", number: 2},
        {address: "0x44508a95e29Bc2E96aBf13f10aD57E1148Ec3712", cutAddress: "0x44508a........Ec3712", number: 1},
        {address: "0x5480c42DE7067b53D76B5bc64875f31a292DbbC6", cutAddress: "0x5480c4........2DbbC6", number: 1},
        {address: "0x9c95a54cfA5e26dE931c7adA0DDC60Def85b909F", cutAddress: "0x9c95a5........5b909F", number: 2},
        {address: "0x72ed1425da8bE4b574Aba0062c1353352e59Ab69", cutAddress: "0x72ed14........59Ab69", number: 1},
        {address: "0x4c62a6995a874166Fe2802Ba0b2609Bd74eDc8Bb", cutAddress: "0x4c62a6........eDc8Bb", number: 1},
        {address: "0x29e4078fe088EB4A98F70E8460C3bDfD7C1C1917", cutAddress: "0x29e407........1C1917", number: 2},
        {address: "0x9755775a5c63198015757e1B90c1D8413073637C", cutAddress: "0x975577........73637C", number: 1},
        {address: "0xfc03595240c2F6F00038213504A1682A7b8504F9", cutAddress: "0xfc0359........8504F9", number: 2},
        {address: "0xE526F94E3b4613adb769f59A583dF9f7468E5635", cutAddress: "0xE526F9........8E5635", number: 2},
        {address: "0x3adAC36D5e4478551ccDd35A6959213f89E62A46", cutAddress: "0x3adAC3........E62A46", number: 1},
        {address: "0x4f84EDDAA0B1B2a696C61b12807c566159aF6970", cutAddress: "0x4f84ED........aF6970", number: 1},
        {address: "0x87730116Dd13C2Ffe933692FFddE875e4eAB2EE8", cutAddress: "0x877301........AB2EE8", number: 3},
        {address: "0x9B71c8aAf6d9bF8EA91a42d74726ffc35b075103", cutAddress: "0x9B71c8........075103", number: 1},
        {address: "0xfa9Ff941Fdc872Fd2af467bF29811983c204e06D", cutAddress: "0xfa9Ff9........04e06D", number: 1},
        {address: "0x74d7cdFf6DbB6d70715d1da0C746CDeE714D457B", cutAddress: "0x74d7cd........4D457B", number: 1},
        {address: "0xa87BAE4afEb922D6DbC23ab93dACe2e618CD0771", cutAddress: "0xa87BAE........CD0771", number: 1},
        {address: "0x059C16799a7dC83E25C2BF4032D0151252f87570", cutAddress: "0x059C16........f87570", number: 1},
        {address: "0xE2C5C0f863B5049446e6df66CD12959633c1Ca63", cutAddress: "0xE2C5C0........c1Ca63", number: 2},
        {address: "0xab4bDbaadcEd9d78C238B3A94d4b96CC767B5e8e", cutAddress: "0xab4bDb........7B5e8e", number: 1},
        {address: "0x2091Cd7C82c58bc752Cb040cc0f2Ea1CBce90c55", cutAddress: "0x2091Cd........e90c55", number: 1},
        {address: "0x7B3CD4Ddb96E608Bb31782c87DB54D638c276CeC", cutAddress: "0x7B3CD4........276CeC", number: 1},
        {address: "0x9F260a990859Bca817E23B634b811939c11dc831", cutAddress: "0x9F260a........1dc831", number: 1},
        {address: "0x7292CDcC9E127aAFb90a2d2BDe3B542616Bba990", cutAddress: "0x7292CD........Bba990", number: 1},
        {address: "0xeb68c0C67381492d2E2348d5A6d977bC4833Ac86", cutAddress: "0xeb68c0........33Ac86", number: 1},
        {address: "0xf9dd984B85aA585d56FC214804C2831758d9C80b", cutAddress: "0xf9dd98........d9C80b", number: 1},
        {address: "0xE3b067E8347b8c1659006A693fCc0Ca39c81E1dF", cutAddress: "0xE3b067........81E1dF", number: 1},
        {address: "0x6C783F294b8fa46fff6ea3E8c73C2Eb0dd786C4a", cutAddress: "0x6C783F........786C4a", number: 2},
        {address: "0xC877FaadaF59Cf556C260b6848B5a35393Da95DA", cutAddress: "0xC877Fa........Da95DA", number: 1},
        {address: "0xeE535A77F68cF4b7117831F84322fa1Fd0bCfc3C", cutAddress: "0xeE535A........bCfc3C", number: 1},
        {address: "0x449Ad0828B225E88E05cff8319C26F11E47f99C7", cutAddress: "0x449Ad0........7f99C7", number: 1},
        {address: "0xE00cC6daD126440427FB0BCE334D36C12D4E6AEd", cutAddress: "0xE00cC6........4E6AEd", number: 1},
        {address: "0x11De1A55242cEe061f84ba2435fea1610f2A4809", cutAddress: "0x11De1A........2A4809", number: 1},
        {address: "0x7273a3f2084d711b423BC3aa0484EAe637E95830", cutAddress: "0x7273a3........E95830", number: 2},
        {address: "0x9c1F7C1645BAEF91afD0e37BBd9F7cdb29c12997", cutAddress: "0x9c1F7C........c12997", number: 1},
        {address: "0x2263098DbB83eD9a9ee2C86a91E42551e30F3c17", cutAddress: "0x226309........0F3c17", number: 1},
        {address: "0x74c9b302a80FC6c5e14f9619975C4166Fa3FD659", cutAddress: "0x74c9b3........3FD659", number: 1},
        {address: "0xBcb1eCa6A1EFaac5086af57D766127798bCdc651", cutAddress: "0xBcb1eC........Cdc651", number: 1},
        {address: "0x2Ec40F1fd18ECd6Af2647e59467187b25e3dC906", cutAddress: "0x2Ec40F........3dC906", number: 2},
        {address: "0xb1D0008Cc41d19C6770b5035bc703891CE108a9d", cutAddress: "0xb1D000........108a9d", number: 1},
        {address: "0x94c91dF7F3377BD4c2a36D0e3482b056934c332a", cutAddress: "0x94c91d........4c332a", number: 2},
        {address: "0xA4983508Ac804AC23A724bE88D391a634eb20a34", cutAddress: "0xA49835........b20a34", number: 1},
        {address: "0x46EA29Af4E6431dbea830C57B45A0752641883A4", cutAddress: "0x46EA29........1883A4", number: 1},
        {address: "0x5411722B1C4c7Cc795Ad1b29F8a24d9955E09560", cutAddress: "0x541172........E09560", number: 1},
        {address: "0x034B054e80e72E66dAFdd61C515BB5cC401B258C", cutAddress: "0x034B05........1B258C", number: 1},
        {address: "0x5A95Db5fC3431a3e44d5Dc112be11252FA64Cf61", cutAddress: "0x5A95Db........64Cf61", number: 1},
        {address: "0x486F79CAdC28B4938b51CF6771ba8Cdfe8255b51", cutAddress: "0x486F79........255b51", number: 1},
        {address: "0xd9D511a59B46596B724449Df3787DBE150086a3f", cutAddress: "0xd9D511........086a3f", number: 1},
        {address: "0x0d2e0083dDE4D115f842C6cfC59Ed17ccA6Bb7c9", cutAddress: "0x0d2e00........6Bb7c9", number: 1},
        {address: "0xf799D96849D1Bf1e3BBd77E334a2421087cc9b58", cutAddress: "0xf799D9........cc9b58", number: 1},
        {address: "0xBf564Fce8c03eb3D78d04764FC0527f9CB785420", cutAddress: "0xBf564F........785420", number: 1},
        {address: "0xa3a8671687f65B34D910f9A39E8a71B86c65F29C", cutAddress: "0xa3a867........65F29C", number: 1},
        {address: "0xabEd43b6F3490a1CCBf50A9Ff67B5b588f517595", cutAddress: "0xabEd43........517595", number: 1},
        {address: "0x2f4310BE61DD32584A16C5cC5D2830405909E7A1", cutAddress: "0x2f4310........09E7A1", number: 1},
        {address: "0x173707e4429c2807D28e6B76e2660d52888aE721", cutAddress: "0x173707........8aE721", number: 1},
        {address: "0x956E25bAE6486f56Dc48130D96258E3Ac9cc7DE0", cutAddress: "0x956E25........cc7DE0", number: 2},
        {address: "0x79A3dd6E58FaE89e3B85d9C99fB028d082992271", cutAddress: "0x79A3dd........992271", number: 1},
        {address: "0xdB70C3d03C35Ea59a77F49e47849E963d1E273aA", cutAddress: "0xdB70C3........E273aA", number: 1},
        {address: "0x7b659EDaf918890793e5Bd2Aa48AABe20Ae5d50b", cutAddress: "0x7b659E........e5d50b", number: 2},
        {address: "0xF62bae92E61294c2f9b1E7582Cc22F810Ee395f9", cutAddress: "0xF62bae........e395f9", number: 1},
        {address: "0xE6e6300F76817e23d3C569d43dddd642f85f2481", cutAddress: "0xE6e630........5f2481", number: 2},
        {address: "0xB4d6d09b073AC4c6306F0720414A359C06a235Ad", cutAddress: "0xB4d6d0........a235Ad", number: 1},
        {address: "0x82449a0361441Af601298006d94291B711d0A9aE", cutAddress: "0x82449a........d0A9aE", number: 2},
        {address: "0x6Ba0752Da79BF94d38E30Dcc8c77D2856bfBBA38", cutAddress: "0x6Ba075........fBBA38", number: 1},
        {address: "0x04aCcB2c2835F0feDB1cd67A644D3ebBA49c7399", cutAddress: "0x04aCcB........9c7399", number: 1},
        {address: "0xaAC6dCcc2D8356D9C4345216b6A865Ef4f08D86C", cutAddress: "0xaAC6dC........08D86C", number: 1},
        {address: "0xF4ddC547c00b91A8E3ACcf03070a0ffb10bC3cC9", cutAddress: "0xF4ddC5........bC3cC9", number: 1},
        {address: "0x42e0316bfFE8490c4611Fc17876Ceb7c209381d4", cutAddress: "0x42e031........9381d4", number: 1},
        {address: "0x365C8A9605e94A78982015dfD314bA9F1957ddF0", cutAddress: "0x365C8A........57ddF0", number: 1},
        {address: "0x3AA40613B820003c564b40CA8df5D42439987B86", cutAddress: "0x3AA406........987B86", number: 1},
        {address: "0xa7E27e3D777a8d7fbC7FcD7086C0E0DC8BC607ec", cutAddress: "0xa7E27e........C607ec", number: 1},
        {address: "0xE594b9931FB4f452f84D7FeA7F453DAc0898113D", cutAddress: "0xE594b9........98113D", number: 1},
        {address: "0x7Db8A70Bc8276Ed904A7164567D190AdD266d015", cutAddress: "0x7Db8A7........66d015", number: 2},
        {address: "0x296829AACC68dEA9392790BCB0Bf2128478AFc54", cutAddress: "0x296829........8AFc54", number: 2},
        {address: "0xDb18D7799B61b43aD637dB92e3BDf2833903491F", cutAddress: "0xDb18D7........03491F", number: 2},
        {address: "0x8f7a294BbF1a856480Cb7BF46001b1C370130A1B", cutAddress: "0x8f7a29........130A1B", number: 2},
        {address: "0x1d094e6a15E0c4acaa671759Bd4f1e424D134467", cutAddress: "0x1d094e........134467", number: 1},
        {address: "0xF0D86Edb8786e9156ec77BbEF820472D05E66AAd", cutAddress: "0xF0D86E........E66AAd", number: 2},
        {address: "0xf1711C0Ad798C04cDD9A89cEE8EEB0d62c9A0f0e", cutAddress: "0xf1711C........9A0f0e", number: 1},
        {address: "0x9110352b2C8a1b2Ebc61b17Ad7F79242F2Ea9aC5", cutAddress: "0x911035........Ea9aC5", number: 1},
        {address: "0x335dCd8bEC97a32Eb3eBb07dE747D3e0dF7B1f93", cutAddress: "0x335dCd........7B1f93", number: 1},
        {address: "0xe165F1f74148E763bfE0C1b83ae2B8f16BA5E1e4", cutAddress: "0xe165F1........A5E1e4", number: 1},
        {address: "0x75F9c2dD20f549F3ADDf88e5A6C78c7aB99A3A01", cutAddress: "0x75F9c2........9A3A01", number: 2},
        {address: "0x880E998CF6649Bd26CBf809a178F9Aa2B6769D16", cutAddress: "0x880E99........769D16", number: 1},
        {address: "0xF0Fa2F7C92c8E45EE25aC8418517397542EFEeF6", cutAddress: "0xF0Fa2F........EFEeF6", number: 2},
        {address: "0x888887C22292630F2531579831040Ec8c1213075", cutAddress: "0x888887........213075", number: 1},
        {address: "0xa85306B12449fAf3e1dDCca15A316332Aea8ea9B", cutAddress: "0xa85306........a8ea9B", number: 1},
        {address: "0x3788265d43d427477a5d90993d32e32425A14a6f", cutAddress: "0x378826........A14a6f", number: 1},
        {address: "0x8f8A6A00fcf71eCf27b04BfF11f7042356AAa7e0", cutAddress: "0x8f8A6A........AAa7e0", number: 1},
        {address: "0x29cB2Eaf6b9B12a17c708D6246911F6893BC6d4e", cutAddress: "0x29cB2E........BC6d4e", number: 1},
        {address: "0x5E806d5F651F4056df19530926d4a92f14c1E2f6", cutAddress: "0x5E806d........c1E2f6", number: 2},
        {address: "0x8789eA17E53781bC0E745ABF786173F6d63185A6", cutAddress: "0x8789eA........3185A6", number: 1},
        {address: "0x7857Bb1170CCf4241A552379365d28AB968Fd6d3", cutAddress: "0x7857Bb........8Fd6d3", number: 1},
        {address: "0xe212c1330a24C4151cA142F8e0da778303ca2b2e", cutAddress: "0xe212c1........ca2b2e", number: 1},
        {address: "0xfE1c612180D687C7D1a5643A86Ea3084f0C46715", cutAddress: "0xfE1c61........C46715", number: 1},
        {address: "0xe9F074541d72aAF8cd2Ef8EF1928b238b39eBEB8", cutAddress: "0xe9F074........9eBEB8", number: 1},
        {address: "0x8846Bcc592CFEc8Eb6C994B6853d6bb726F6bE6e", cutAddress: "0x8846Bc........F6bE6e", number: 1},
        {address: "0xFc734fCaC0283dB4CBd942d26e875323FB9B5b87", cutAddress: "0xFc734f........9B5b87", number: 1},
        {address: "0x7f8Cf8391e42341A88912791683E24CeBD604531", cutAddress: "0x7f8Cf8........604531", number: 1},
        {address: "0x0AeDc7a3BE570f6bA1518455ae1aF4b7A046C2Ff", cutAddress: "0x0AeDc7........46C2Ff", number: 1},
        {address: "0x1FeBf9093A07C7a98624A4db2C5fA36826AaEd41", cutAddress: "0x1FeBf9........AaEd41", number: 1},
        {address: "0x51770eeEA85F47C892748303413E8ec8C29F79db", cutAddress: "0x51770e........9F79db", number: 1},
        {address: "0xDD841191C6eA9F529287872b92743e61d535be52", cutAddress: "0xDD8411........35be52", number: 1},
        {address: "0x8d2067F92383d152b5ca155ECCC285F1060D0a53", cutAddress: "0x8d2067........0D0a53", number: 1},
        {address: "0x0e7DfF6C716feA935e3508EB06A2E44A4CdB4Fe0", cutAddress: "0x0e7DfF........dB4Fe0", number: 1},
        {address: "0x75fAdee002512c80984239d0993e5028e0CC8fd4", cutAddress: "0x75fAde........CC8fd4", number: 1},
        {address: "0xE1338D10958796beAc1B2bA93C77AF5cAA0759EB", cutAddress: "0xE1338D........0759EB", number: 1},
        {address: "0xc094c119bEAA02327fdb25AC7fBB48d9E8EF8144", cutAddress: "0xc094c1........EF8144", number: 1},
        {address: "0xFd3Be88FD1E435f8C8151352CB3c82B120C26Dd7", cutAddress: "0xFd3Be8........C26Dd7", number: 1},
        {address: "0x728425F7FE6c32EA431eeC95B1Edc9A93a1f21e7", cutAddress: "0x728425........1f21e7", number: 1},
        {address: "0xBCfbf4b14F0e1447627B5852a79Dc8da2083CdD3", cutAddress: "0xBCfbf4........83CdD3", number: 2},
        {address: "0x016dBB64ff36810cb7eF68bFC87284A420cd2B50", cutAddress: "0x016dBB........cd2B50", number: 1},
        {address: "0x5e95093Dc352eF5e2e3340A13122c88583B8671a", cutAddress: "0x5e9509........B8671a", number: 1},
        {address: "0x3D97E3ffBAf5f1af96de2324b48D596F0e311a25", cutAddress: "0x3D97E3........311a25", number: 2},
        {address: "0xD1134750f2ec5d5Ad121692182dfbC8525ABBa9a", cutAddress: "0xD11347........ABBa9a", number: 1},
        {address: "0x00f90b1E0AD1fEAaA9cD813949B101dAF24aB09D", cutAddress: "0x00f90b........4aB09D", number: 1},
        {address: "0x65e1B9a04C51B045AF1Eeec6F8eEe927080a571C", cutAddress: "0x65e1B9........0a571C", number: 1},
        {address: "0xb2F8c5409F46CEf251336fBa9970c113172fA757", cutAddress: "0xb2F8c5........2fA757", number: 1},
        {address: "0x0fC74288BdF05Ea5226BFab38AbCBBeC347D7346", cutAddress: "0x0fC742........7D7346", number: 1},
        {address: "0x81e8ea69a0cC65c72c8Edb2Ad4bd48594723DbAa", cutAddress: "0x81e8ea........23DbAa", number: 1},
        {address: "0x557a4D990f94aF4041f28D80C37512e809aA755F", cutAddress: "0x557a4D........aA755F", number: 1},
        {address: "0x9379f41be85a6e1C19F2Bdc0A5D2B00570cb1BF9", cutAddress: "0x9379f4........cb1BF9", number: 1},
        {address: "0x0C1d5a303F5CAC63158b21a92479dAe4D1f68E5F", cutAddress: "0x0C1d5a........f68E5F", number: 1},
        {address: "0x6cde8AD92C7808B0867cF7f26899d816C771F019", cutAddress: "0x6cde8A........71F019", number: 1},
        {address: "0x5796fDEB5D0DCAC3946B3A9e65B1D3c2F1fB9835", cutAddress: "0x5796fD........fB9835", number: 1},
        {address: "0x93451DEbBa7264D626110070a91995ADc8AbA12E", cutAddress: "0x93451D........AbA12E", number: 1},
        {address: "0xD3D0A779EEca1f6d2f2625dF03fcA0f99C7E2659", cutAddress: "0xD3D0A7........7E2659", number: 2}]

    const arrResultBonus = [
        {address: "0xfB4FE144c38Ec53ba3a700E86897196fB86f900a", cutAddress: "0xfB4FE1........6f900a", number: 1},
        {address: "0x1D88Ca41f64C2F1c826e0d6EB4Df847ed2988Bcf", cutAddress: "0x1D88Ca........988Bcf", number: 1},
        {address: "0xA3D64Bf3c3D4241e8C92298879f02B17c7a49134", cutAddress: "0xA3D64B........a49134", number: 1},
        {address: "0x94386f0871b68Ee5A87cCC3D660561312484ff29", cutAddress: "0x94386f........84ff29", number: 1},
        {address: "0x13963c7197F95C06846e3e33F0d3A95697A85B5A", cutAddress: "0x13963c........A85B5A", number: 1},
        {address: "0x1f3A397730573402ACB25925eBf05a8B01b50935", cutAddress: "0x1f3A39........b50935", number: 2},
        {address: "0xb7D80cC5B953e99482747507375250C90fF011b3", cutAddress: "0xb7D80c........F011b3", number: 1}]

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 500)
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 2000)
        // let myInterval = setInterval(() => {
        //     getInfo();
        // }, 20000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    }, []);

    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }
    const approveContract = (tokenContract, snackbar, onComplete, onError) => {
        const web3 = getWeb3();
        const MAX_APPROVE = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
        const ninneko = new web3.eth.Contract(TokenABI, tokenContract);
        handleResult(ninneko.methods.approve(NinnekoStakeFelixAddress, MAX_APPROVE), snackbar, onComplete, onError);
    }
    const approve = (tokenContract) => {
        setLoading(true);
        approveContract(tokenContract, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const [stakeValue, setStakeValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    // const setMaxStake = () => {
    //     setStakeValue(myNino)
    // }

    const stake = (poolId) => {
        setLoading(true);
        deposit(poolId, stakeValue, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }
    const deposit = (pool, amount, snackbar, onComplete, onError) => {
        const web3 = getWeb3();
        const contract = new web3.eth.Contract(NinnekoStakeFelix, NinnekoStakeFelixAddress);
        handleResult(contract.methods.stake(pool, web3.utils.toWei(amount)), snackbar, onComplete, onError);
    }

    const [openBuyBox, setOpenBuyBox] = useState(false);
    // const [approved, setApproved] = useState(false);
    const [openROIDailog, setOpenROIDailog] = useState(false);

    /*    const approveBuyBox = () => {
            setLoading(true);
            approveBuyNFT(enqueueSnackbar, () => {
                getInfo();
                setLoading(false);
                enqueueSnackbar("Now you can buy box", { variant: 'success', autoHideDuration: 10000 });
            }, onError);
        }*/
    /*    const buyBox = (numberOfBox) => {
            setOpenBuyBox(false);
            setLoading(true);
            buyNFTBox(numberOfBox, enqueueSnackbar, () => {
                getInfo();
                setLoading(false);
                enqueueSnackbar("Buy " + parseInt(numberOfBox) + " boxes successfully!", { variant: 'success', autoHideDuration: 10000 });
            }, onError, 0.11 * numberOfBox);
        }*/

    const withdrawAll = (pool, snackbar, onComplete, onError) => {
        const web3 = getWeb3();
        const address = getWalletAddress();
        const ninnekoWhitelist = new web3.eth.Contract(NinnekoStakeFelix, NinnekoStakeFelixAddress);
        ninnekoWhitelist.methods.userInfo(pool, address).call({from: address}).then(data => {
            return handleResult(ninnekoWhitelist.methods.unStake(pool), snackbar, onComplete, onError);
        })
    }
    const withdraw = (poolId) => {
        setLoading(true);
        withdrawAll(poolId, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const handleClickOpenROI = () => {
        setOpenROIDailog(true);
    };

    const renderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            return <span>Event has ended</span>;
        } else {

            return <span>Ends in: {days}d {hours}h {minutes}min {seconds}</span>;
        }

    };
    const BOX_STAKE_FELIX = [
        {
            boxType: BOX_TYPE_RUBY,
            boxImage: "/images/nino_coin.png",
            boxPoint: "50",
            boxName: "Nino Coin"
        },

    ]

    const handleClaim = async () => {
        setLoading(true);
        claimToken(enqueueSnackbar, () => {
            getInfo()
            setLoading(false)
        }, onError);
    };

    return (
        <Grid container style={{maxWidth: "800px"}}>
            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={12} md={6} lg={6}>
                        <div>
                            <Typography color="textPrimary" variant="h5">
                                3-month term Pool 2
                                <br/>
                            </Typography>
                            {/* <Typography color="#3990f8" variant="body2">
                                <Countdown
                                    date={"Fri Dec 17 2021 10:00:00 GMT+0700"}
                                    renderer={renderer}
                                />
                            </Typography> */}
                            <Typography color="#3990f8" variant="body2">Stake any time, high APR</Typography>

                            {/* <div>
                                <a href= {PATH.WHITE_LIST_RANKING.path} className={classes.bannerLink} target="_blank">
                                    Show ranking</a>
                            </div> */}
                            {/*   <div>
                                <a href={PATH.WHITE_LIST_RESULT.path} className={classes.bannerLink} target="_blank">
                                    Winner List</a>
                            </div>*/}

                            {canBuyBox &&
                                <div className={classes.buyBox}>
                                    <Button className={classes.buyBoxButton} disabled={loading || walletAddress == null}
                                            onClick={() => setOpenBuyBox(true)} variant="contained">Buy Box with
                                        BNB</Button>
                                </div>
                            }

                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {/* <MHidden width={"smDown"}> */}
                        <Grid container>
                            {
                                BOX_STAKE_FELIX.map((box, key) => {
                                        return (
                                            <Grid item xs={6} md={6} key={key}>
                                                <div className={classes.box}>
                                                    <div className={classes.boxImageWrapper}>
                                                        <div className={classes.boxImage}>
                                                            <img src={box.boxImage}/>
                                                        </div>
                                                        {/* <div className={classes.boxPoint}>
                                                            {box.boxPoint}
                                                        </div> */}
                                                        {/* <Typography color="textPrimary">500 NINO</Typography> */}
                                                    </div>
                                                    {/* <div className={classes.boxName}>
                                                        {box.boxName}
                                                    </div> */}
                                                </div>
                                            </Grid>
                                        )
                                    }
                                )
                            }
                        </Grid>
                        {/* </MHidden> */}
                    </Grid>
                    {/* <Typography color="textSecondary" variant="body2"><br />You can start buying box in November 24</Typography>     */}
                </Card>
            </Grid>
            <Grid item xs={12}>
               {/* className={classes.stakingWrapper}*/}
                <Grid container >
                    {
                        pools.map((pool, key) => {
                            console.log({
                                myEarn: pool.myEarn?pool.myEarn:0 ,
                                rewardPerSecon: pool.rewardPerSecond,
                                totalNino: pool.totalNinoStake
                            })
                            //Hot fix nếu số lượng NINO vượt quá max nino stake
                            if (pool.totalNinoStake>7200000){
                                pool.totalNinoStake=7200000
                            }
                            return (
                                <Grid container key={key}>
                                    <Grid container className={classes.stakingRow}>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">My Nino</Typography>
                                            <div  className={classes.stakingContent}>
                                                {(pool.myEntries?pool.myEntries:0).toFixed(1)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">APR (%)</Typography>
                                            <div className={classes.stakingContent}>
                                                {pool.rewardPerSecond && pool.myEarn ? "100" : "--"}
                                                {/* {'Loading'}*/}
                                                <Link href="#">
                                                    <CalculateIcon className={classes.calculatorIcon}
                                                                   onClick={() => handleClickOpenROI()}/>
                                                </Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">My earn</Typography>
                                            <div
                                                className={classes.stakingContent}>{pool?.myEarn}</div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">Total Stake</Typography>
                                            <div
                                                className={classes.stakingContent}>{(pool?.totalNinoStake).toFixed(1) }</div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.stakingActionWrapper}>
                                        <div className={classes.stakingActionWrapperInner}>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <div className={classes.stakingActionWrapperAction}>
                                                        <Typography variant="body2">Enter the amount to
                                                            stake</Typography>
                                                        <Paper className={classes.stakingButtonWrapper}
                                                               component="form"
                                                               sx={{
                                                                   p: '2px 4px',
                                                                   display: 'flex',
                                                                   alignItems: 'center',
                                                                   width: '100%'
                                                               }}
                                                        >
                                                            <InputBase
                                                                sx={{ml: 1, flex: 1}}
                                                                inputProps={{'type': 'number'}}
                                                                value={stakeValue}
                                                                onChange={event => {
                                                                    setStakeValue(event.target.value)
                                                                }}
                                                            />
                                                            {
                                                                walletAddress == null &&
                                                                <Button className={classes.stakingButton} disabled
                                                                        variant="contained">Connect your wallet to
                                                                    Stake</Button>
                                                            }
                                                            {
                                                                walletAddress != null && !pool.approved &&
                                                                <Button className={classes.stakingButton}
                                                                        onClick={
                                                                            () => {
                                                                                approve(pool.tokenContract)
                                                                            }
                                                                        }
                                                                        variant="contained">Approve</Button>
                                                            }
                                                            {
                                                                pool.approved &&
                                                                <Button className={classes.stakingButton}
                                                                        onClick={() => stake(pool.poolId)}
                                                                        disabled={loading || !stakeValue}
                                                                    // disabled={true}
                                                                        variant="contained">Stake</Button>
                                                            }

                                                        </Paper>
                                                    </div>
                                                </Grid>

                                                {/*<Grid item xs={12} sm={6}>
                                                    <div className={classes.stakingActionWrapperAction}>
                                                        {
                                                            walletAddress != null &&
                                                            <Button className={classes.withdrawButton}
                                                                    disabled={loading || !pool.myEarn || pool.myEarn <= 0}
                                                                    onClick={() => withdraw(pool.poolId)}
                                                                    variant="contained">Withdraw</Button>
                                                        }
                                                    </div>
                                                </Grid>*/}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            {/*<Typography variant="body2" style={{ paddingTop: 16 }}>*/}
            {/*    You CAN NOT unstake (withdraw) your tokens until 3 AM December 22, 2021 (UTC)*/}
            {/*</Typography>*/}
            {/* <WhitelistROIDialog open={openROIDailog} onClose={() => setOpenROIDailog(false)} />*/}
            {/*<BuyBoxWhitelistDialog buyNFTBox={buyBox} loading={loading}*/}
            {/*                       open={openBuyBox} onClose={() => setOpenBuyBox(false)} />*/}
            <Grid container style={{maxWidth: "800px"}}>
                {true &&
                    <Grid container>
                        <Grid item xs={12}>
                            {/*<h1>Stake Lock Claim</h1>*/}
                        {/*    <span className={classes.desc}>*/}
                        {/*    Please connect your Wallet first!*/}
                        {/*</span>*/}
                        </Grid>
                    </Grid>
                }
                {/*walletAddress*/}
                {walletAddress &&
                    <Grid item xs={12}>
                        <Card className={classes.banner}>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.info}>
                                        <Typography color="textPrimary" variant="h5">
                                            Claim Stake Lock
                                            <br/>
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={12} className={classes.info}>
                                        <Grid container>
                                            <Grid item xs={5} md={2} className={classes.buyBox}>
                                                <Typography color="textPrimary">Total NINO:</Typography>
                                            </Grid>

                                            <Grid item xs={5} md={10} className={classes.buyBox}>
                                                <Typography color="textPrimary">{token.totalToken}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className={classes.info}>
                                        <Grid container>
                                            <Grid item xs={5} md={2} className={classes.buyBox}>
                                                <Typography color="textPrimary">Available:</Typography>
                                            </Grid>
                                            <Grid item xs={5} md={10} className={classes.buyBox}>
                                                {
                                                    pools.map((pool,key)=>{

                                                        return  <Typography color="textPrimary">{parseFloat(token.totalToken)  + parseFloat(pool.myEarn) }</Typography>
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <LoadingButton onClick={handleClaim} variant="contained"
                                               disabled={walletAddress == null /**|| token.availableToken <= 0 */ || token.totalToken <= 0}
                                               loading={loading}
                                               style={{width: '100%', height: 40}}>
                                    Claim
                                </LoadingButton>
                            </Grid>
                        </Card>
                        {/*   <Typography>
                            You can claim NINO everyday over 3 months
                        </Typography>*/}
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} paddingTop={2}>
                <Grid container className={classes.stakingWrapper}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" padding={1}>
                            Staking pool with a 3-month term.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} paddingTop={1}>
                        <Typography variant="body2" paddingLeft={2}>
                            - Last in: 3 months from Aug/16th to Nov/13th.<br/>
                            - Total NINO distributed for the interests: 1,800,000 <br/>
                            - Limit of $NINO that can be staked: 7,200,000 tokens. <br/>
                            - Tokens you stake will be locked while the pool is open. <br/>
                            - Minimum APR: 100% (You are guaranteed a minimum interest rate of 100%/year) <br/>
                            - After 3-month term Pool ends: you can get your interests straightaway. Your staked tokens
                            will be released by vesting in 1 month.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <APYFlexible open={openROIDailog} onClose={() => setOpenROIDailog(false)}/>
        </Grid>
    );
}
export default StakeLock2;