import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import { WhitelistROIDialog } from "../../component/Misc/WhitelistROIDialog";
import {
    fetchRanking
} from "../../service/whitelist";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';

import DialogContent from '@mui/material/DialogContent';
import TableContainer from "@mui/material/TableContainer";
import { Table } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { BootstrapDialog, BootstrapDialogTitle } from "../../component/Misc/BootstrapDialog";
import { getPastEvents } from "../../service/sapphire";
import LoadingSpinner from "../../component/Misc/LoadingSpinner";
import { TEST_NET } from "../../util/constants";

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "25px"
    },
    bannerLink: {
        fontSize: "14px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "20px"
    },
    buyBoxButton: {
        marginTop: "5px"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10px"
    },
    boxImageWrapper: {
        width: "135px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "120px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
}));

export function SapphireRanking() {
    const classes = useStyles()

    // const [myNino, setMyNino] = useState(0);
    const [ranking, setRanking] = useState(null);
    const DECIMAL = 1e18;

    const getInfo = async () => {
        var fromBlock = TEST_NET ? 17008387 : 15535975
        var objData = { event: 'Stake', fromBlock: fromBlock, toBlock: "latest", chunkLimit: 5000 }
        let result = await getPastEvents(objData);

        var rankingData = {}
        for (let i = 0; i < result.events.length; i++) {
            var item = result.events[i];
            var itemEvent = item.returnValues

            var staker = itemEvent.staker
            var amount = itemEvent.amount

            if (rankingData.hasOwnProperty(staker)) {
                rankingData[staker] = rankingData[staker] + (amount / DECIMAL)
            } else {
                rankingData[staker] = amount / DECIMAL
            }
        }

        var newRanking = []
        for (const [key, value] of Object.entries(rankingData)) {
            newRanking.push({
                "address": key,
                "amount": value,
            })
        }

        newRanking.sort(function (a,b){
            return b.amount - a.amount;
        });

        for (let i = 0; i < newRanking.length; i++) {
            newRanking[i].top = i+1;
            let cutAddress = newRanking[i].address.substring(0,6) + "..." + newRanking[i].address.substring(newRanking[i].address.length - 6,newRanking[i].address.length)
            newRanking[i].address = cutAddress
        }

        setRanking(newRanking)
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
        }, 500)
        // setTimeout(()=> {
        //     getInfo();
        // }, 2000)
        // let myInterval = setInterval(() => {
        //     getInfo();
        // }, 20000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    }, []);

    if (!ranking) return <LoadingSpinner />

    return (
        <Grid container style={{ maxWidth: "800px" }}>
            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={12}>
                        <div>
                            <Typography color="textPrimary" variant="h5">
                                Ranking
                                <br />
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Top</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Staked amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ranking && ranking.map((data, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell>{data.top}</TableCell>
                                                <TableCell>{ data.address}</TableCell>
                                                <TableCell>{data.amount.toFixed(2)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}


