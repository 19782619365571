import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

import { PATH } from "../../config/routes";
import { makeStyles } from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import { WhitelistROIDialog } from "../../component/Misc/WhitelistROIDialog";
import { approveBuyNFT, buyNFTBox, checkAllowanceBuyNFT, checkSoldOut } from "../../service/sapphire";
import { BOX_SAPPHIRE } from "../../config/staking";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';
import { HelpOutlined } from "@mui/icons-material";
import Countdown from 'react-countdown';
import { BuySapphireBoxDialog } from "../../component/Misc/BuySapphireBoxDialog";
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    adBanner: {
        display: "flex",
        borderRadius: "10px",
        background: "#1976d2", //theme.palette.primary.main , // "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        // border: "1px solid #000",
        // boxSizing: "border-box",
        // boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "25px"
    },
    bannerLink: {
        fontSize: "16px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "20px"
    },
    buyBoxButton: {
        marginTop: "5px",
        backgroundColor: "#1976d2"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    boxImageWrapper: {
        width: "180px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "180px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
}));

export function TopRef() {
    const classes = useStyles()

   

    const renderer = ({ days,hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>Event has ended</span>;
          } else {
        
          return <span>Ends in: {days}d {hours}h {minutes}min {seconds}</span>;
        }
        
      };


    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData(1, '0x7fc***c2aaa', 159, 6.0),
        createData(2, '0x7fc***c2aaa', 237, 9.0),
        createData(3, '0x7fc***c2aaa', 262, 16.0),
        createData(4, '0x7fc***c2aaa', 305, 3.7),
        createData(5, '0x7fc***c2aaa', 356, 16.0),
    ];

    return (
        <Grid container style={{ maxWidth: "800px" }}>
            <Grid item xs={12}>
                <Card className={classes.adBanner}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography color="white" variant="h3">
                            Invitation Competition
                        </Typography>
                        {/* <Typography color="white" variant="body1">
                            <Countdown
                                date={"Dec 28 2021 20:00:00 GMT+0700"}
                                renderer={renderer}
                            />
                        </Typography> */}
                        <Typography color="white">
                            <br />
                            More players, more rewards! &nbsp;
                            <a href="https://t.me/ninnekoann"
                                //className={classes.bannerLink} 
                                style={{ color: 'white' }}
                                target="_blank">
                                Learn more
                            </a>
                        </Typography>
                        <br />
                        <Countdown
                            date={"Feb 20 2022 09:03:14 GMT+0700"}
                            renderer={renderer}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container style={{ justifyContent: 'center' }}>
                            <img src="/images/char3.png" />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Rank</TableCell>
                                    <TableCell align="right">Wallet</TableCell>
                                    <TableCell align="right">Points</TableCell>
                                    <TableCell align="right">Rewards</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Grid>
        </Grid>
    );
}


