const MySvg1 = '/images/svg/h1.svg';
const MySvg2 = '/images/svg/h2.svg';
const MySvg3 = '/images/svg/h3.svg';
const MySvg4 = '/images/svg/h4.svg';
const MySvg5 = '/images/svg/h5.svg';
const MySvg6 = '/images/svg/h6.svg';


const MySvgClass1 = '/images/svg/n1.svg';
const MySvgClass2 = '/images/svg/n2.svg';
const MySvgClass3 = '/images/svg/n3.svg';
const MySvgClass4 = '/images/svg/n4.svg';
const MySvgClass5 = '/images/svg/n5.svg';




export const DRAWER_WIDTH = 280

export function LightningIcon({ width = 25, height = 25, className }) {
    //return <img src="/imagess/pet/bolt.png"} width={width} height={height} className={className}/>
    return <img src={MySvg5} width={width} height={height} className={className} />
}

export function EarthIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg3} width={width} height={height} className={className} />
}


export function FireIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg1} width={width} height={height} className={className} />
}

export function WindIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg2} width={width} height={height} className={className} />
}

export function WaterIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg4} width={width} height={height} className={className} />
}

export function YinYangIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg6} width={width} height={height} className={className} />
}


export function CaptainIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass4} width={width} height={height} className={className} />
}

export function GuardIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass1} width={width} height={height} className={className} />
}

export function SupportIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass5} width={width} height={height} className={className} />
}

export function ScoutIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass2} width={width} height={height} className={className} />
}

export function AssassinIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass3} width={width} height={height} className={className} />
}




export const FACTIONS = [
    {
        id: 1,
        icon: LightningIcon,
        label: "Bolt"
    },
    {
        id: 2,
        icon: FireIcon,
        label: "Fire"
    },
    {
        id: 3,
        icon: EarthIcon,
        label: "Earth"
    },
    {
        id: 4,
        icon: WindIcon,
        label: "Wind"
    },
    {
        id: 5,
        icon: WaterIcon,
        label: "Water"
    },
    {
        id: 6,
        icon: YinYangIcon,
        label: "YinYang"
    },
]


export const CLASSES = [
    {
        id: 1,
        icon: CaptainIcon,
        label: "Mage"
    },
    {
        id: 2,
        icon: GuardIcon,
        label: "Warrior"
    },
    {
        id: 3,
        icon: SupportIcon,
        label: "Support"
    },
    {
        id: 4,
        icon: ScoutIcon,
        label: "Ranger"
    },
    {
        id: 5,
        icon: AssassinIcon,
        label: "Assassin"
    },
]

export const CLASSES_ICON = [MySvgClass4, MySvgClass1, MySvgClass5, MySvgClass2, MySvgClass3]



export const GENERATION_ALPHA = 1
export const GENERATION_BETA = 2

export const GENERATIONS = [
    {
        id: GENERATION_ALPHA,
        label: "Alpha"
    },

]

export const SALE_STATS = [
    {
        id: 1,
        label: "For sale"
    },
    {
        id: 2,
        label: "Not sale"
    },

]

export const PETS_PER_PAGE = 17
export const ERROR_TYPE_TRANSACTION = 1
export const PET_AVATAR_SIZE = 215
export const PET_BOX_SIZE = 160
export const PET_STAGE_COTTON_BOX = 1
export const PET_STAGE_SPECIAL_BOX = 2
export const PET_STAGE_PET = 0


//////------------------------------------Game Config Cần đồng bộ với server------------------------

export const BREED_AGE = 6 //days
export const MAX_BREED_COUNT = 6
// export const BREED_COST_MATA = [
//     100,
//     200,
//     300,
//     600,
//     1000,
//     2000,
//     3500,
//     7200,
//     10000
// ]

//trên chain thì thêm 000000000000000000
export const BREED_COST_MATA = [
    100,
    200,
    300,
    600,
    1000,
    2000,
]
//trên chain thì thêm 000000000000000000
export const BREED_COST_NINO = 5
export const BASE_STATS = {
    //atk, arm, hp, spd
    1: { 'atk': 5500, 'arm': 188, 'hp': 64000, 'spd': 78 },
    2: { 'atk': 2750, 'arm': 314, 'hp': 80000, 'spd': 65 },
    3: { 'atk': 2750, 'arm': 188, 'hp': 72000, 'spd': 130 },
    4: { 'atk': 4400, 'arm': 188, 'hp': 56000, 'spd': 117 },
    5: { 'atk': 4950, 'arm': 220, 'hp': 48000, 'spd': 104 },
}
export const GEN_TYPE_WILD = 1
export const BONUS_STATS_CONST = 0.05
export const BONUS_FROM_CLASS_OF_PART = {
    1: {'hp':1,'atk' :3},//mage
    2: {'hp':2,'arm':2},//warrior
    3: {'spd':3,'hp':1},//support
    4: {'atk':2,'spd':2},//ranger
    5: {'atk':2,'arm':1,'spd':1},//asssasin
}
export const CLAIM_PERIOD = 15

//-------------------------------------------------------------------------


export const TEST_NET = false

export const METAMASK_PROVIDER = 'METAMASK'
export const WALLETCONNECT_PROVIDER = 'WALLETCONNECT'



export const NETWORK_VERSION = TEST_NET ? 97 : 56
export const NETWORK_VERSION_HEX = TEST_NET ? '0x61' : '0x38'
export const NETWORK_RPC_URL = TEST_NET ? "https://data-seed-prebsc-2-s3.binance.org:8545/" : "https://bsc-dataseed.binance.org/";

export const API_URL = 'https://game-t.ninneko.com/xs/'

export const AD_REF_API_URL = TEST_NET ? "https://api-test.ninneko.com/rest/user/refer" : "https://api.ninneko.com/rest/user/refer"


export const NETWORK_CONFIG = TEST_NET ?
    [{
        chainId: NETWORK_VERSION_HEX,
        chainName: 'Binance Smart Chain Testnet',
        nativeCurrency:
        {
            name: 'TESTBNB',
            symbol: 'TESTBNB',
            decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-2-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
    }]
    :
    [{
        chainId: NETWORK_VERSION_HEX,
        chainName: 'Binance Smart Chain',
        nativeCurrency:
        {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    }]
