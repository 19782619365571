import {generateRoutes} from "../util/generateRoutes";
import {TEST_NET} from '../util/constants';

export const PATH = generateRoutes({
    BASE_API: "https://api.ninneko.com",
    GRAPH_API: TEST_NET ? "https://api-test.ninneko.com/graphql" : "https://api.ninneko.com/graphql",
    API_RANDOM_STRING: "https://api.ninneko.com/rest/misc/randomString",
    API_LOGIN: "https://api.ninneko.com/rest/user/login",
    API_REGISTER: "https://api.ninneko.com/rest/user/register",
    API_FORGOT_PASSWORD: "https://api.ninneko.com/rest/password/forgot",
    API_RESET_PASSWORD: "https://api.ninneko.com/rest/password/reset",
    HOME: "/",
    STAKING: "/",
    STAKING_ME: "/staking/me",
    ACCOUNT: "/account/",
    LOGOUT: "/logout",
    SETTINGS: "/account/settings",
    WHITE_LIST: '/whitelist',
    WHITE_LIST_RANKING: '/ranking',
    LOTTERY: "/lottery",
    NEW_GENES: "/newgenes",
    SAPPHIRE: "/sapphire",
    SAPPHIRE_RANKING: '/sapphire/ranking',
    SAPPHIRE_RESULT: '/sapphire/result',
    COTTON: "/cotton",
    WHITE_LIST_RESULT: '/whitelist/result',
    RESULT_AIRDROP_ALPHA_TEST: "/airdrop/alpha-test/result",
    TYPE_BOX_TEST: "/test",
    CLAIM_VESTING: "/vesting",
    PARTNER:"/partner",
    CLAIM_REWARDS:"/rewards",
    CLAIM_ADVISOR:"/advisor",
    STAKE_FELIX:"/flexiblepool",
    STAKE_LOCK:"/3monthtermpool",
    GEM: "/gem",
    STAKE_LOCK_2:"/3monthtermpool2"

})