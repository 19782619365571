import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";
import { LoadingButton } from '@mui/lab';

import { PATH } from "../../config/routes";
import { makeStyles } from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import { WhitelistROIDialog } from "../../component/Misc/WhitelistROIDialog";
import { approveBuyNFT, approveSapphireStaking, buyNFTBox, buySapphireBoxStaking, checkAllowanceBuyNFT, checkApproveStakingContract, checkSoldOut, getNINOStaked, getNumberOfNINO, getTokenAvailable, stake, unstake } from "../../service/sapphire";
import { BOX_SAPPHIRE } from "../../config/staking";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';
import { HelpOutlined } from "@mui/icons-material";
import Countdown from 'react-countdown';
import { BuySapphireBoxDialog } from "../../component/Misc/BuySapphireBoxDialog";
import { MHidden } from "../../component/@material-extend";
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    bannerImg: {
        display: "flex",
        borderRadius: "10px",
        backgroundImage: 'url("/images/banner1.jpg")',
        height: 450,
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    adBanner: {
        display: "flex",
        borderRadius: "10px",
        background: "#1976d2", //theme.palette.primary.main , // "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        // border: "1px solid #000",
        // boxSizing: "border-box",
        // boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "25px"
    },
    bannerLink: {
        fontSize: "16px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "20px"
    },
    buyBoxButton: {
        marginTop: "5px",
        backgroundColor: "#1976d2"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    boxImageWrapper: {
        width: "180px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "180px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
    info: {
        paddingTop: 10
    },
}));

const REQUIRED_NUMBER_NINO_STAKING = 10000
const REQUIRED_NUMBER_NINO_BUY_BOX = 10000

export function Sapphire() {
    const classes = useStyles()

    const [walletAddress, setWalletAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [openBuyBox, setOpenBuyBox] = useState(false);
    const [approved, setApproved] = useState(false);
    const [isSoldOut, setIsSoldOut] = useState(true);

    const [availableToken, setAvailableToken] = useState(0);
    const [approvedStaking, setApprovedStaking] = useState(null);
    const [myNINOStaked, setMyNINOStaked] = useState(0)

    const [stakeValue, setStakeValue] = useState(0);

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        // setApproved(await checkAllowanceBuyNFT());
        // setIsSoldOut(await checkSoldOut())
        setApprovedStaking(await checkApproveStakingContract())
        setMyNINOStaked(await getNINOStaked())
        setAvailableToken(await getTokenAvailable())
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 500)
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 2000)
        // let myInterval = setInterval(() => {
        //     getInfo();
        // }, 20000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    }, []);

    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }

    const approveBuySapphireBox = () => {
        setLoading(true);
        approveBuyNFT(enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Now you can buy box", { variant: 'success', autoHideDuration: 10000 });
        }, onError);
    }

    const buyBox = (numberOfBox) => {
        setOpenBuyBox(false);
        setLoading(true);
        buyNFTBox(numberOfBox, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Buy " + parseInt(numberOfBox) + " boxes successfully!", { variant: 'success', autoHideDuration: 10000 });
        }, onError);
    }

    const handleApproveSapphireStaking = () => {
        setLoading(true);
        approveSapphireStaking(enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const handleStake = () => {
        setLoading(true);
        stake(stakeValue, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const hanldeBuyBoxStaking = (numberOfBox) => {
        setOpenBuyBox(false);
        setLoading(true);
        buySapphireBoxStaking(numberOfBox, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Buy " + parseInt(numberOfBox) + " boxes successfully!", { variant: 'success', autoHideDuration: 10000 });
        }, onError);
    }

    const handleUnstake = () => {
        setLoading(true);
        unstake(enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>;
        } else {

            return <span>Staking round: {days}d {hours}h {minutes}min {seconds}</span>;
        }

    };

    return (

        <Grid container style={{ maxWidth: "800px" }}>
            <MHidden width='mdDown'>
                <Grid item xs={12}>
                    <Card className={classes.bannerImg}>

                    </Card>
                </Grid>
            </MHidden>
            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={12} md={6} lg={6}>
                        <div>
                            <Typography color="textPrimary" variant="h5" style={{ paddingBottom: 6 }}>
                                Sapphire Box
                                <br />

                            </Typography>
                            {/* <Typography color="textPrimary">Event has ended</Typography> */}

                            <Typography color="#3990f8" variant="body2">
                                <Countdown
                                    date={"Fri Feb 25 2022 18:00:00 GMT+0700"}
                                    renderer={renderer}
                                />
                            </Typography>

                            <a href={PATH.SAPPHIRE_RANKING.path} className={classes.bannerLink} target="_blank">
                                Show ranking</a>

                            <div className={classes.buyBox}>
                                <Typography color="textPrimary">My Stake: {walletAddress ? myNINOStaked + " NINO" : "--"}</Typography>
                                <Button className={classes.buyBoxButton} disabled={loading || !walletAddress || !approvedStaking}
                                    onClick={() => setOpenBuyBox(true)} variant="contained">Buy Box with
                                    NINO</Button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container>
                            {
                                BOX_SAPPHIRE.map((box, key) => {
                                    return (
                                        <Grid item xs={6} md={6} key={key}>
                                            <div className={classes.box}>
                                                <div className={classes.boxImageWrapper}>
                                                    <div className={classes.boxImage}>
                                                        <img src={box.boxImage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                }
                                )
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.stakingWrapper}>
                    <Grid container>
                        <Grid item xs={12} className={classes.stakingActionWrapper}>
                            <div className={classes.stakingActionWrapperInner}>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <div className={classes.stakingActionWrapperAction}>
                                            <Typography variant="body2">Stake NINO</Typography>
                                            <Paper className={classes.stakingButtonWrapper}
                                                component="form"
                                                sx={{
                                                    p: '2px 4px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                <InputBase
                                                    sx={{ ml: 1, flex: 1 }}
                                                    inputProps={{ 'type': 'number' }}
                                                    value={stakeValue}
                                                    // disabled={true}
                                                    disabled={approvedStaking == null}
                                                    onChange={event => {
                                                        setStakeValue(event.target.value)
                                                    }}
                                                />
                                                {
                                                    walletAddress == null &&
                                                    <Button className={classes.stakingButton} disabled
                                                        variant="contained">Connect your wallet to
                                                        Stake</Button>
                                                }
                                                {
                                                    walletAddress != null && !approvedStaking &&
                                                    <LoadingButton className={classes.stakingButton}
                                                        loading={loading}
                                                        // disabled={true}
                                                        disabled={approvedStaking == null}
                                                        onClick={() => handleApproveSapphireStaking()}
                                                        variant="contained">Approve</LoadingButton>
                                                }
                                        {/*        {
                                                    approvedStaking &&
                                                    <LoadingButton className={classes.stakingButton}
                                                        loading={loading}
                                                        // disabled={true}
                                                        // disabled={myNINOStaked > 0}
                                                        onClick={() => handleStake()}
                                                        variant="contained">Stake</LoadingButton>
                                                }*/}
                                            </Paper>
                                        </div>
                                    </Grid>

                                     <Grid item xs={12}>
                                        <div className={classes.stakingActionWrapperAction}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Grid container>
                                                        <Typography color="textPrimary" variant="h6" style={{ paddingBottom: 6 }}>
                                                            Unstake NINO
                                                        </Typography>
                                                        <Grid item xs={12} className={classes.info}>
                                                            <Grid container>
                                                                <Grid item xs={5} md={4}>
                                                                    <Typography color="textPrimary" variant="h7">Total NINO:</Typography>
                                                                </Grid>

                                                                <Grid item xs={5} md={8}>
                                                                    <Typography color="textPrimary" variant="h7">{myNINOStaked}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} className={classes.info}>
                                                            <Grid container>
                                                                <Grid item xs={5} md={4}>
                                                                    <Typography color="textPrimary" variant="h7">Available:</Typography>
                                                                </Grid>
                                                                <Grid item xs={5} md={8}>
                                                                    <Typography color="textPrimary" variant="h7">{availableToken}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={2} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                                    <LoadingButton onClick={handleUnstake} variant="contained"
                                                        disabled={walletAddress == null || availableToken <= 0}
                                                        loading={loading}
                                                        style={{ width: '100%', height: 40 }}>
                                                        Claim
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="body2" style={{ paddingTop: 16 }}>
                - There are 2 rounds: staking (22h) and buying (48h) <br />
                - You can stake more than one time, the more NINO you stake, the more boxes you can buy<br />
                - The number of boxes that each user can buy will be announced after staking round<br />
                - After buying round, if you don't buy any box, you can unstake all of your NINO<br />
                - If you buy at least 1 box, you can claim your staked NINO daily in 15 days<br /><br /><br />

            </Typography>
            <BuySapphireBoxDialog buyNFTBox={hanldeBuyBoxStaking} loading={loading}
                open={openBuyBox} onClose={() => setOpenBuyBox(false)} />
        </Grid>

        
        // </Grid>
    );
}


