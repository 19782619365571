import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { BOX, BOX_TYPE_COTTON, BOX_TYPE_MATA, BOX_WHITE_LIST, BOX_TYPE_RUBY, BOX_SAPPHIRE, BOX_TYPE_SAPPHIRE, BOX_PARTNER, BOX_TYPE_PARTNER, BOX_TYPE_DARK_SWORD, BOX_DARK_SWORD } from "../../config/staking";
import { getNFTBox, approveBoxHandler, checkApproveBoxHandler, openNFTRubyBox, openNFTCottonBox, openMATABox } from "../../service/whitelist";
import { checkApproveSapphireBoxHandler, approveSapphireBoxHandler, openNFTSapphireBox } from "../../service/sapphire";
import { approvePartnerBoxHandler, checkApprovePartnerBoxHandler, openNFTPartnerBox } from "../../service/partner";
import sessionData from "../../sessionData";
import { OpenPetBoxDialog } from "../../component/Misc/OpenPetBoxDialog";
import { useApolloClient, useMutation, useQuery, useServerTime } from '@apollo/client';
import { QUERY_PET } from '../../graphql/User/marketQuery';
import { TEST_NET } from "../../util/constants";
import { approveNewGeneBoxHandler, checkApproveNewGeneBoxHandler, openNFTNewGeneBox } from "../../service/newgene";

const useStyles = makeStyles((theme) => ({
    desc: {
        fontSize: "16px",
        lineHeight: "19px",
    },
    nftWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
        marginTop: "20px",
        width: "220px",
        padding: "20px",
        // paddingLeft:"10px",
        // paddingRight:"10px"

    },
    nftPoint: {
        textAlign: "right",
        marginBottom: "10px",
        fontSize: "14px",
        fontWeight: "600",
        color: "black",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    nftImageBox: {
        width: "100%",

    },
    nftImage: {
        width: "100%",
    },
    nftClaim: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    nftClaimButton: {
        width: "100%"
    },
    boxInfo: {
        textAlign: "center"
    },
    approveButton: {
        width: "100%",
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    contentHeader: {
        display: "inline-block",
        [theme.breakpoints.down('sm')]: {
            minWidth: 270,
            minHeight: 284,
        },
    }
}));

export function MyNFTBox() {
    const classes = useStyles()
    const [boxes, setBoxes] = useState(null);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [approve, setApprove] = useState(false);
    const [approveSapphire, setApproveSapphire] = useState(false);
    const [approvePartner, setApprovePartner] = useState(false);
    const [approveNewGene, setApproveNewGene] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [lastPetIdOpen, setLastPetIdOpen] = useState(1)
    const client = useApolloClient()

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getBoxInfo();
        }, 500)
        setTimeout(() => {
            getBoxInfo();
        }, 2000)
        // let myInterval = setInterval(() => {
        //     getBoxInfo();
        // }, 30000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    }, []);

    const variables = {
        id: lastPetIdOpen
    }

    const { loadingQuerry, error, data, refetch } = useQuery(QUERY_PET, { variables })

    const refetchQuery = async (petId) => {
        variables.id = petId
        console.log("petId: " + petId)
        refetch(variables)
        await client.refetchQueries({
            include: 'all'
        });
        // console.log("refetchQuerry")
    }

    if (error) {
        return `Error! ${error.message}`;
    }

    const getBoxInfo = async () => {
        const nftBoxes = await getNFTBox()
        setBoxes(nftBoxes)

        const boolApprove = await checkApproveBoxHandler()
        setApprove(boolApprove)

        setApproveSapphire(await checkApproveSapphireBoxHandler())

        setApprovePartner(await checkApprovePartnerBoxHandler())

        setApproveNewGene(await checkApproveNewGeneBoxHandler())
    }

    const getBoxCount = () => {
        if (boxes == null) return [0, 0, 0]
        let mataBoxCount = 0
        let cottonBoxCount = 0
        let rubyBoxCount = 0
        let sapphireBoxCount = 0
        let partnerBoxCount = 0
        let darkSwordBoxCount = 0
        boxes.forEach(box => {
            // console.log("box.boxType: " + box.boxType)
            if (box.boxType == BOX_TYPE_MATA) mataBoxCount++
            if (box.boxType == BOX_TYPE_COTTON) cottonBoxCount++
            if (box.boxType == BOX_TYPE_RUBY) rubyBoxCount++
            if (box.boxType == BOX_TYPE_SAPPHIRE) sapphireBoxCount++
            if (box.boxType == BOX_TYPE_PARTNER) partnerBoxCount++
            if (box.boxType == BOX_TYPE_DARK_SWORD) darkSwordBoxCount++
        });

        return [mataBoxCount, cottonBoxCount, rubyBoxCount, sapphireBoxCount, partnerBoxCount, darkSwordBoxCount];
    }

    const onError = () => {
        setLoading(false)
    }

    const disableOpenBox = (boxCount) => {
        if (boxCount > 0) return false

        return true
    }

    const handleOpenMATABox = async (boxType) => {
        setLoading(true);
        if (boxes != null) {
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].boxType != boxType) continue

                openMATABox(boxes[i].boxId, enqueueSnackbar, () => {
                    getBoxInfo()
                    setLoading(false)
                }, onError)

                return
            }
        }
    }

    const handleOpenCottonBox = async (boxType) => {
        setLoading(true);
        if (boxes != null) {
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].boxType != boxType) continue

                openNFTCottonBox(boxes[i].boxId, enqueueSnackbar, (petId) => {
                    setLastPetIdOpen(petId)
                    getBoxInfo()
                    setLoading(false)
                    refetchQuery(petId)
                    var timer = setInterval(async () => {
                        await refetchQuery(petId)

                        if (data.pet.avatarURL) {
                            clearInterval(timer)
                            setOpenDialog(true)
                        }
                    }, 2000)

                }, onError)

                return
            }
        }
    }

    const handleOpenRubyBox = async (boxType) => {
        setLoading(true);
        if (boxes != null) {
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].boxType != boxType) continue

                openNFTRubyBox(boxes[i].boxId, enqueueSnackbar, (petId) => {
                    setLastPetIdOpen(petId)
                    getBoxInfo()
                    setLoading(false)
                    refetchQuery(petId)
                    var timer = setInterval(async () => {
                        await refetchQuery(petId)

                        if (data.pet.avatarURL) {
                            clearInterval(timer)
                            setOpenDialog(true)
                        }
                    }, 2000)

                }, onError)

                return
            }
        }
    }

    const handleOpenSapphireBox = async (boxType) => {
        setLoading(true);
        if (boxes != null) {
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].boxType != boxType) continue

                openNFTSapphireBox(boxes[i].boxId, enqueueSnackbar, (petId) => {
                    setLastPetIdOpen(petId)
                    getBoxInfo()
                    setLoading(false)
                    refetchQuery(petId)
                    var timer = setInterval(async () => {
                        await refetchQuery(petId)

                        if (data.pet.avatarURL) {
                            clearInterval(timer)
                            setOpenDialog(true)
                        }
                    }, 2000)

                }, onError)

                return
            }
        }
    }

    const handleOpenPartnerBox = async (boxType) => {
        setLoading(true);
        if (boxes != null) {
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].boxType != boxType) continue

                openNFTPartnerBox(boxes[i].boxId, enqueueSnackbar, (petId) => {
                    setLastPetIdOpen(petId)
                    getBoxInfo()
                    setLoading(false)
                    refetchQuery(petId)
                    var timer = setInterval(async () => {
                        await refetchQuery(petId)

                        if (data.pet.avatarURL) {
                            clearInterval(timer)
                            setOpenDialog(true)
                        }
                    }, 2000)

                }, onError)

                return
            }
        }
    }

    const handleOpenNewGeneBox = async (boxType) => {
        setLoading(true);
        if (boxes != null) {
            for (let i = 0; i < boxes.length; i++) {
                if (boxes[i].boxType != boxType) continue

                openNFTNewGeneBox(boxes[i].boxId, enqueueSnackbar, (petId) => {

                    console.log("new pet "+petId)
                    setLastPetIdOpen(petId)
                    getBoxInfo()
                    setLoading(false)
                    refetchQuery(petId)
                    var timer = setInterval(async () => {
                        await refetchQuery(petId)

                        if (data.pet.avatarURL) {
                            clearInterval(timer)
                            setOpenDialog(true)
                        }
                    }, 2000)

                }, onError)

                return
            }
        }
    }

    const handleApproveSapphireBoxHandleContract = () => {
        setLoading(true);

        approveSapphireBoxHandler(enqueueSnackbar, () => {
            setLoading(false)
            setApproveSapphire(true)
        }, onError)
    }

    const handleApprovePartnerBoxHandleContract = () => {
        setLoading(true);

        approvePartnerBoxHandler(enqueueSnackbar, () => {
            setLoading(false)
            setApprovePartner(true)
        }, onError)
    }

    const handleApproveNewGeneBoxHandleContract = () => {
        setLoading(true);

        approveNewGeneBoxHandler(enqueueSnackbar, () => {
            setLoading(false)
            setApprove(true)
        }, onError)
    }

    const handleApproveBoxHandleContract = () => {
        setLoading(true);

        approveBoxHandler(enqueueSnackbar, () => {
            setLoading(false)
            setApprove(true)
        }, onError)
    }

    return (
        sessionData.myAddress != null ?
            <Grid container style={{ paddingLeft: 10 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={3}>
                        <h1>My Box</h1>
                    </Grid>
                    {/* <Grid item xs={6} md={9}>
                        {!approve &&
                            <LoadingButton className={classes.approveButton}
                                onClick={handleApproveBoxHandleContract}
                                variant="contained"
                                loading={loading}>
                                Approve
                            </LoadingButton>
                        }
                    </Grid> */}

                    {/* <Grid item xs={12} style={{ paddingBottom: 6 }}>
                    {!approve &&
                        <span className={classes.desc}>
                            You need to approve first to open Box
                        </span>
                    }
                    </Grid> */}
                </Grid>

                <Grid container spacing={1}>
                    {
                        BOX.map((box, key) => {
                            return (
                                <Grid item xs={12} md={3} key={key} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className={classes.nftWrapper}>
                                        <div className={classes.nftImageBox}>
                                            <img className={classes.nftImage} src={box.boxImage} key={key} />
                                        </div>

                                        {boxes && <Typography className={classes.boxInfo}>{box.boxName + ": " + getBoxCount()[box.boxType]}</Typography>}

                                        {boxes && !approve &&
                                            <div className={classes.nftClaim}>
                                                <LoadingButton className={classes.approveButton}
                                                    // style={{background: "rgb(25, 118, 210)", borderRadius: "30px"}}
                                                    onClick={handleApproveBoxHandleContract}
                                                    variant="contained"
                                                    loading={loading}>
                                                    Approve
                                                </LoadingButton>
                                            </div>
                                        }

                                        {boxes && approve &&
                                            <div className={classes.nftClaim}>
                                                <LoadingButton disabled={disableOpenBox(getBoxCount()[box.boxType])} className={classes.nftClaimButton}
                                                    variant="contained" onClick={() => {
                                                        if (box.boxType !== BOX_TYPE_MATA) handleOpenCottonBox(box.boxType)
                                                        else handleOpenMATABox(box.boxType)
                                                    }} loading={loading}>
                                                    Open
                                                </LoadingButton>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            )
                        }
                        )
                    }

                    {BOX_WHITE_LIST.map((box, key) => {
                        return (
                            <Grid item xs={12} md={3} key={key} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.nftWrapper}>
                                    <div className={classes.nftImageBox}>
                                        <img className={classes.nftImage} src={box.boxImage} key={key} />
                                    </div>

                                    {boxes && <Typography className={classes.boxInfo}>{box.boxName + ": " + getBoxCount()[box.boxType]}</Typography>}

                                    {boxes && !approve &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton className={classes.approveButton}
                                                // style={{background: "rgb(25, 118, 210)", borderRadius: "30px"}}
                                                onClick={handleApproveBoxHandleContract}
                                                variant="contained"
                                                loading={loading}>
                                                Approve
                                            </LoadingButton>
                                        </div>
                                    }

                                    {boxes && approve &&
                                        <div className={classes.nftClaim}>

                                            <LoadingButton disabled={disableOpenBox(getBoxCount()[box.boxType])} className={classes.nftClaimButton}
                                                variant="contained" onClick={() => { handleOpenRubyBox(box.boxType) }} loading={loading}>
                                                Open
                                            </LoadingButton>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        )
                    }
                    )
                    }
                    {BOX_SAPPHIRE.map((box, key) => {
                        return (
                            <Grid item xs={12} md={3} key={key} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.nftWrapper}>
                                    <div className={classes.nftImageBox}>
                                        <img className={classes.nftImage} src={box.boxImage} key={key} />
                                    </div>

                                    {boxes && <Typography className={classes.boxInfo}>{box.boxName + ": " + getBoxCount()[box.boxType]}</Typography>}

                                    {boxes && !approveSapphire &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton className={classes.approveButton}
                                                // style={{background: "rgb(25, 118, 210)", borderRadius: "30px"}}
                                                // disabled={true}
                                                onClick={handleApproveSapphireBoxHandleContract}
                                                variant="contained"
                                                loading={loading}>
                                                Approve
                                            </LoadingButton>
                                        </div>
                                    }

                                    {boxes && approveSapphire &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton
                                                // disabled={true} 
                                                disabled={disableOpenBox(getBoxCount()[box.boxType])}
                                                className={classes.nftClaimButton}
                                                variant="contained" onClick={() => { handleOpenSapphireBox(box.boxType) }} loading={loading}>
                                                Open
                                            </LoadingButton>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        )
                    }
                    )
                    }

                    {BOX_PARTNER.map((box, key) => {
                        return (
                            <Grid item xs={12} md={3} key={key} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.nftWrapper}>
                                    <div className={classes.nftImageBox}>
                                        <img className={classes.nftImage} src={box.boxImage} key={key} />
                                    </div>

                                    {boxes && <Typography className={classes.boxInfo}>{box.boxName + ": " + getBoxCount()[box.boxType]}</Typography>}

                                    {boxes && !approvePartner &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton className={classes.approveButton}
                                                // style={{background: "rgb(25, 118, 210)", borderRadius: "30px"}}
                                                disabled={disableOpenBox(getBoxCount()[box.boxType])}
                                                onClick={handleApprovePartnerBoxHandleContract}
                                                variant="contained"
                                                loading={loading}>
                                                Approve
                                            </LoadingButton>
                                        </div>
                                    }

                                    {boxes && approvePartner &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton
                                                // disabled={true} 
                                                disabled={disableOpenBox(getBoxCount()[box.boxType])}
                                                className={classes.nftClaimButton}
                                                variant="contained" onClick={() => { handleOpenPartnerBox(box.boxType) }} loading={loading}>
                                                Open
                                            </LoadingButton>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        )
                    })}

                    {BOX_DARK_SWORD.map((box, key) => {
                        return (
                            <Grid item xs={12} md={3} key={key} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.nftWrapper}>
                                    <div className={classes.nftImageBox}>
                                        <img className={classes.nftImage} src={box.boxImage} key={key} />
                                    </div>

                                    {boxes && <Typography className={classes.boxInfo}>{box.boxName + ": " + getBoxCount()[box.boxType]}</Typography>}

                                    {boxes && !approveNewGene &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton className={classes.approveButton}
                                                // style={{background: "rgb(25, 118, 210)", borderRadius: "30px"}}
                                                disabled={disableOpenBox(getBoxCount()[box.boxType])}
                                                onClick={handleApproveNewGeneBoxHandleContract}
                                                variant="contained"
                                                loading={loading}>
                                                Approve
                                            </LoadingButton>
                                        </div>
                                    }

                                    {boxes && approveNewGene &&
                                        <div className={classes.nftClaim}>
                                            <LoadingButton
                                                // disabled={true} 
                                                disabled={disableOpenBox(getBoxCount()[box.boxType])}
                                                className={classes.nftClaimButton}
                                                variant="contained" onClick={() => { handleOpenNewGeneBox(box.boxType) }} loading={loading}>
                                                Open
                                            </LoadingButton>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
                <OpenPetBoxDialog open={openDialog} onClose={() => setOpenDialog(false)} ninneko={data} />
            </Grid>
            :
            <Grid container>
                <Grid item xs={12}>
                    <h1>My Box</h1>
                    <span className={classes.desc}>
                        Please connect your Wallet first!
                    </span>
                </Grid>
            </Grid>
    );
}


