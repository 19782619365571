// ----------------------------------------------------------------------

export default function Timeline() {
  return {
    MuiTimeline: {
      styleOverrides: {
        root: {
          position:"left"
        }
      }
    }
  };
}
